import { Table } from "antd";
import { Status } from "@/Components/shared";
import { NetCatResponseData, generateConnectionString } from "@/shared";
import { setColumns } from ".";

interface IBeaconSession {
    status: Status;
    lastSeen: string;
    targetHostId: string;
    connectionString: string;
}

type Props = {
    sessions: Sessions;
    handleStopSession: (value: any) => void;
}

export class BeaconSession implements IBeaconSession {
    status: Status = '';
    lastSeen: string = '';
    targetHostId: string = '';
    connectionString: string = '';

    constructor(data: NetCatResponseData) {
        this.status = data.status;
        this.lastSeen = data.updated_at.Time;
        this.targetHostId = data.target_host.target_host_id;
        this.connectionString = generateConnectionString(data);
    }
}

export type Sessions = {
    revshell?: BeaconSession | undefined;
    proxy?: BeaconSession | undefined;
};

export const BeaconSessions = ({ sessions, handleStopSession }: Props): JSX.Element => {
    return (<>
        {Object.entries(sessions).map(([sessionType, session]: [string, BeaconSession]): JSX.Element => {
            return <div className="beacon_session" key={sessionType}>
                <b>{sessionType}: </b>
                <Table showHeader={false} pagination={false}
                    className={`session_table session_table_${sessionType}`}
                    columns={setColumns(handleStopSession, sessionType as keyof Sessions, session.status === 'disconnected')}
                    dataSource={[
                        {
                            key: sessionType,
                            ...session
                        }
                    ]} />
            </div>
        })}
    </>);
}
