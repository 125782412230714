import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { FloatButton, Modal, Table } from "antd";
import { useEffect } from "react";
import { ClientsApi } from "@/Api";
import { User, getCookie } from "@/shared";
import get_user_columns from "../columns/userColumns";
import ManageUserModal from "../Modals/ManageUserModal";
import ManageClientModal from "../Modals/ManageClientModal";
import { useAdminState } from "../Store";
import { useParams } from "react-router-dom";

const USER_SERVICE = new ClientsApi();

const UserPage: React.FC = () => {
  const token = getCookie("token") as string;
  const routeParams = useParams();
  const [state, actions] = useAdminState();

  const getUsersList = async () => {
    actions.setUsers(await USER_SERVICE.getUsersByClient(`${routeParams.id}`));
  };

  const deleteUser = async (id: any) => {
    Modal.confirm({
      title: "Are you sure you want to delete the user?",
      okText: "yes",
      okType: "danger",
      onOk: async () => {
        await USER_SERVICE.removeUserById(id);
        getUsersList();
      },
    });
  };

  const updateUser = async (user: User) => {
    await USER_SERVICE.updateUser(user);
    getUsersList();
    actions.setEditedUser(undefined);
  };

  const setNewUser = async (newUser: User) => {
    newUser!.client_id = `${routeParams.id}`;
    if (newUser!.roles === undefined) {
      newUser!.roles = ["collector"];
    }
    await USER_SERVICE.addNewUser(newUser);
    getUsersList();
  };

  const editClient = async () => {
    actions.setEditedClient(
      await USER_SERVICE.getClientById(`${routeParams.id}`)
    );
    actions.setIsClientEdit(true);
  };

  const updateClient = async (client: any) => {
    await USER_SERVICE.updateClient(client);
  };

  const render_actions = (record: any) => {
    return (
      <>
        <EditOutlined
          title="Edit user"
          onClick={() => {
            actions.setEditedUser(record);
            actions.setIsUserEdit(true);
          }}
          style={{ color: "green", marginLeft: "30px" }}
        />
        <DeleteOutlined
          title="Delete user"
          onClick={() => {
            deleteUser(record.id);
          }}
          style={{ color: "red", marginLeft: "35px", padding: "5px" }}
        />
      </>
    );
  };

  useEffect(() => {
    getUsersList();
    document.title = "Users / BartUI ";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !state.isUserEdit && actions.setEditedUser(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isUserEdit]);

  return !token ? (
    <></>
  ) : (
    <div className="messages_table">
      <Table
        className="usersTable"
        columns={get_user_columns(
          render_actions,
          actions.setIsUserEdit,
          editClient
        )}
        dataSource={state.users}
        pagination={{
          defaultPageSize: 40,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "40", "60"],
        }}
        rowKey="id"
      />
      <ManageUserModal
        isOpen={state.isUserEdit}
        updateUser={updateUser}
        setNewUser={setNewUser}
        width={450}
      />
      <ManageClientModal updateClient={updateClient} />
      <FloatButton.BackTop />
    </div>
  );
};
export default UserPage;
