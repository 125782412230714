import React from "react";
import { SearchTargetsModel } from "@/shared";
import { useNetcatState } from "../Store";
import { genDownloadData } from "../actions";
import {
  ColumnSelector,
  SearchTargets,
  TableSettings,
  Toggle,
} from "../../shared";
import { Get_netcat_columns } from "./columns";

type Props = {
  targetsList: SearchTargetsModel[];
};

const TopSection = ({ targetsList }: Props) => {
  const [state, actions] = useNetcatState();

  return (
    <>
      <SearchTargets
        getData={actions.setTableData}
        type={"net"}
        typesList={state.typesList}
        tableData={genDownloadData()}
      />
      <TableSettings>
        <Toggle
          active={state.isActive}
          onClick={actions.setTableData}
          loading={state.isLoading}
          setActive={actions.setActive}
        ></Toggle>
        <ColumnSelector
          tabledata={state.tabledata}
          targetsList={targetsList}
          columns={Get_netcat_columns(
            undefined,
            targetsList,
            actions.setCheckSwith
          )}
          selected_values={
            state.selectedColumns?.map((item) =>
              item.title?.toString()
            ) as string[]
          }
          cookie_name="columns"
          setSelectedColumns={actions.setSelectedColumns}
        />
      </TableSettings>
    </>
  );
};

export default TopSection;
