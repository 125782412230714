import { FilterValue } from "antd/es/table/interface";
import { TargetApi } from "@/Api";
import { TargetModel, TargetNote, actionsType } from "@/shared";
import { parseFavorites } from "../actions";
import { ITargetState } from "./ITargetState";

const TARGET_CLIENT = new TargetApi();

export const actions = {
  setTableData:
    () =>
    async ({ setState, getState }: actionsType<ITargetState>) => {
      setState({ ...getState(), loading: true });
      let tabledata = parseFavorites(await TARGET_CLIENT.getTargetList());
      setState({ ...getState(), tabledata, loading: false });
    },

  setHosts:
    (hosts: any) =>
    ({ setState, getState }: actionsType<ITargetState>) =>
      setState({ ...getState(), hosts }),

  setFavorites:
    (favorites: string) =>
    ({ setState, getState }: actionsType<ITargetState>) =>
      setState({ ...getState(), favorites }),

  setTargetDetails:
    (targetDetails: TargetModel) =>
    ({ setState, getState }: actionsType<ITargetState>) =>
      setState({ ...getState(), targetDetails }),

  setIsRerender:
    (isRerender: boolean) =>
    ({ setState, getState }: actionsType<ITargetState>) =>
      setState({ ...getState(), isRerender }),

  setLoading:
    (loading: boolean) =>
    ({ setState, getState }: actionsType<ITargetState>) =>
      setState({ ...getState(), loading }),

  setIsHostTable:
    (isHostTable: boolean) =>
    ({ setState, getState }: actionsType<ITargetState>) =>
      setState({ ...getState(), isHostTable }),

  setTab:
    (tab: string) =>
    ({ setState, getState }: actionsType<ITargetState>) =>
      setState({ ...getState(), tab }),

  setCollapseKeys:
    (collapseKeys: string[]) =>
    ({ setState, getState }: actionsType<ITargetState>) =>
      setState({ ...getState(), collapseKeys }),

  setHostCollapse:
    (hostCollapse: string[]) =>
    ({ setState, getState }: actionsType<ITargetState>) =>
      setState({ ...getState(), hostCollapse }),

  setIsEdit:
    (isEdit: boolean) =>
    ({ setState, getState }: actionsType<ITargetState>) =>
      setState({ ...getState(), isEdit }),

  setListStyle:
    (listStyle: string) =>
    ({ setState, getState }: actionsType<ITargetState>) => {
      setState({ ...getState(), listStyle });
    },

  setNotes:
    (notes: TargetNote[]) =>
    ({ setState, getState }: actionsType<ITargetState>) => {
      setState({ ...getState(), notes });
    },

  setIsDirtyNote:
    (isDirtyNote: boolean) =>
    ({ setState, getState }: actionsType<ITargetState>) =>
      setState({ ...getState(), isDirtyNote }),

  setFilters:
    (filters: Record<string, FilterValue | null>) =>
    ({ setState, getState }: actionsType<ITargetState>) =>
      setState({ ...getState(), filters }),

  setIsContext:
    (isContext: boolean) =>
    ({ setState, getState }: actionsType<ITargetState>) =>
      setState({ ...getState(), isContext }),

  setRecordContext:
    (record: TargetModel | undefined) =>
    ({ setState, getState }: actionsType<ITargetState>) =>
      setState({ ...getState(), record }),

  setContextPosition:
    (contextPosition: { x: number; y: number }) =>
    ({ setState, getState }: actionsType<ITargetState>) =>
      setState({ ...getState(), contextPosition }),
};
