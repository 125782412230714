import { Tooltip } from "antd";
const gecko = require("@/images/chameleon.png");
const puma = require("@/images/puma.png");
const gwintyk = require("@/images/bolt.png");
const teardrop = require("@/images/crying.png");
const question = require("@/images/question.png");
const gsnetcat = require("@/images/kitty.png");
const proxy = require("@/images/proxy.png");
const mushroom = require("@/images/mushroom.png");
const revshell = require("@/images/reverse.png");

export type BeaconType =
  | "gecko"
  | "puma"
  | "gwyntyk"
  | "mushroom"
  | "teardrop"
  | "gsnetcat"
  | "mycelium"
  | "proxy"
  | "revshell"
  | unknown;

export const BeaconTypeIcon = (
  beaconType: BeaconType,
  index: number
): JSX.Element => {
  const TypeIcon: JSX.Element = (() => {
    switch (beaconType) {
      case "gecko":
        return <img alt="" src={gecko} style={{ color: "green" }} />;
      case "puma":
        return <img alt="" src={puma} style={{ color: "black" }} />;
      case "gwyntyk":
        return <img alt="" src={gwintyk} style={{ color: "red" }} />;
      case "teardrop":
        return <img alt="" src={teardrop} style={{ color: "blue" }} />;
      case "gsnetcat":
        return <img alt="" src={gsnetcat} style={{ color: "green" }} />;
      case "mycelium":
        return <img alt="" src={mushroom} style={{ color: "red" }} />;
      case "proxy":
        return <img alt="" src={proxy} style={{ color: "black" }} />;
      case "revshell":
        return <img alt="" src={revshell} style={{ color: "blue" }} />;
      default:
        return <img alt="" src={question} style={{ color: "pink" }} />;
    }
  })();
  TypeIcon.props.style.width = "25px";
  TypeIcon.props.style.marginRight = "5px";

  return (
    <Tooltip
      title={typeof beaconType === "string" ? beaconType : "other"}
      key={`${beaconType}_${new Date().getTime() * 2}`}
      color={TypeIcon.props.style.color}
    >
      {TypeIcon}
    </Tooltip>
  );
};
