import { AxiosError } from "axios";
import { handleError } from "@/shared";
import { Api } from ".";

export class CredentialsApi {
    getCreds = async (data: any) => {
        try {
            const response = await Api.post("/api/v1/credentials/list", data)
                .then((response: any) => {
                    if (response.data !== null) {
                        return response.data;
                    }
                });
            return response;
        } catch (error) {
            handleError(error as AxiosError);
        }
    }

    getCredsSource = async () => {
        try {
            const response = await Api.get("/api/v1/credentials/sources/list")
                .then((response: any) => {
                    if (response.data !== null) {
                        return response.data;
                    }
                });
            return response;
        } catch (error) {
            handleError(error as AxiosError);
        }
    }

    getCredByID = async (id: any) => {
        try {
            const response = await Api.get(`/api/v1/credentials/${id}`)
                .then((response: any) => {
                    if (response.data !== null) {
                        return response.data;
                    }
                });
            return response;
        } catch (error) {
            handleError(error as AxiosError);
        }
    }
    
    addCreds = async (data: any) => {
        try {
            const response = await Api.post("/api/v1/credentials", data)
                .then((response: any) => {
                    if (response.data !== null) {
                        return response.data;
                    }
                });
            return response;
        } catch (error) {
            handleError(error as AxiosError);
        }
    }

    updateCreds = async (data: any) => {
        try {
            const response = await Api.put(`/api/v1/credentials/${data.id}`, data)
                .then((response: any) => {
                    if (response.data !== null) {
                        return response.data;
                    }
                });
            return response;
        } catch (error) {
            handleError(error as AxiosError);
        }
    }
    
    deleteCreds = async (id: any) => {
        try {
            const response = await Api.remove(`/api/v1/credentials/${id}`)
                .then((response: any) => {
                    if (response.data !== null) {
                        return response.data;
                    }
                });
            return response;
        } catch (error) {
            handleError(error as AxiosError);
        }
    }
}
