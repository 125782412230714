import { Form, Input, Modal, Select } from "antd";
import { useEffect } from "react";
import { getCookie, getOptions } from "@/shared";
import { client_kind_options } from "@/Components/Binary/ui";
import { useAdminState } from "../Store";

type Props = {
  updateClient: (client: any) => Promise<void>;
  setNewClient?: (new_client: any) => Promise<void>;
};

const ManageClientModal = ({ updateClient, setNewClient }: Props) => {
  const roles = getCookie("roles")?.split(",");
  const [state, actions] = useAdminState();
  const [form] = Form.useForm(undefined);

  const validator = {
    Code: [
      {
        required: true,
        pattern: /^.{16}$/,
        message: "must consist of 16 symbols",
      },
    ],
  };

  const submitUpdate = () => {
    form.validateFields().then((result) => {
      state.editedClient
        ? updateClient({ id: state.editedClient?.id, ...result })
        : setNewClient && setNewClient(result);
      clear();
    });
  };

  const handleSelect = (value: any) => {
    if (value.includes("all")) {
      form.setFieldValue(
        "allowed_binaries",
        client_kind_options.filter((i) => i.value !== "all").map((i) => i.value)
      );
    }
  };

  const clear = () => {
    form.resetFields();
    actions.setIsClientEdit(false);
  };

  const setInitValues = () =>
    state.editedClient
      ? state.editedClient
      : { name: "", key: "", allowed_binaries: [], new_session_webhook: "" };

  useEffect(() => {
    if (state.isClientEdit) {
      form.setFieldsValue(setInitValues());
    } else {
      actions.setEditedClient(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isClientEdit]);

  return (
    <Modal
      width={450}
      className="EditClientModal"
      title={state.editedClient ? "Edit client" : "Add new client"}
      open={state.isClientEdit}
      okText="Save"
      onOk={form.submit}
      onCancel={clear}
    >
      <Form
        layout="vertical"
        name="EditClientForm"
        form={form}
        onFinish={submitUpdate}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input placeholder="Enter name" />
        </Form.Item>
        <Form.Item label="key" name="key" required rules={validator.Code}>
          <Input.Password
            visibilityToggle={true}
            placeholder="Enter key (16 symbols)"
          />
        </Form.Item>
        <Form.Item label="Allowed binaries" name="allowed_binaries">
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            options={getOptions(state.current_user.allowed_binaries)}
            placeholder="Select options"
            onChange={handleSelect}
          />
        </Form.Item>
        {roles?.includes("session") ? (
          <Form.Item
            label="New session webhook"
            name="new_session_webhook"
            rules={[{ type: "url" }]}
          >
            <Input placeholder="Enter url" />
          </Form.Item>
        ) : (
          <></>
        )}
      </Form>
    </Modal>
  );
};

export default ManageClientModal;
