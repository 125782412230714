import { DeleteOutlined } from "@ant-design/icons";
import { Modal, Tooltip } from "antd";
import cls from "../SearchTargets/SearchTargets.module.css";
import { HostStore } from "../../TargetHosts/Store";
import { TargetApi } from "@/Api";

const TARGET_CLIENT = new TargetApi();
export const deleteHosts = async (
  getData: (data?: any) => Promise<void> | any
) => {
  Modal.confirm({
    title: `Do you  want to delete multiple hosts  `,
    okText: "Delete",
    okType: "danger",
    onOk: async () => {
      await deleteMultiRows(getData);
      HostStore.actions.setMulti(false);
    },
    onCancel: () => {},
  });
};

const deleteMultiRows = async (
  getData: (data?: any) => Promise<void> | any
) => {
  let keys = HostStore.storeState.getState().selectedRowKeys;
  keys.forEach(async (key) => {
    await TARGET_CLIENT.removeById(key);
  });
  setTimeout(() => getData(), 500);
};

type Props = {
  getData: (data?: any) => Promise<void> | any;
  className?: string;
};

const DeleteMultiBtn = ({ getData, className }: Props) => {
  return (
    <Tooltip placement="topLeft" title={"Delete hosts"}>
      <DeleteOutlined
        onClick={async () => {
          await deleteHosts(getData);
        }}
        title="Delete"
        className={className ? className : cls.showMulti}
        style={{ color: "red" }}
      />
    </Tooltip>
  );
};

export default DeleteMultiBtn;
