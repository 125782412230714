import { createHook, createStore, defaultRegistry } from "react-sweet-state";
import { IHostState } from "./IHostState";
import { actions } from "./Actions";
import { HostInfo } from "@/shared";

const initialState: IHostState = {
  hostDetails: new HostInfo(),
  keys: [],
  logs: [],
  reRender: false,
  tab: "",
  change_alert: false,
};

export const Store = createStore<IHostState, typeof actions>({
  initialState,
  actions,
});

export const useHostState = createHook(Store);

export const HostInfoStore = defaultRegistry.getStore(Store);
