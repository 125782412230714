import { Tabs, TabsProps } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getCookie } from "@/shared";
import Notes from "../../Notes/Notes";
import "../styles.css";
import { useHostState } from "./store";
import { InfoCollapse } from "./ui/infoCollapse";
import { InfoHeader } from "./ui/InfoHeader";

const HostInfoPage = () => {
  const token = getCookie("token") as string;
  const [state, actions] = useHostState();
  const routeParams = useParams();
  const navi = useNavigate();
  const { hash } = window.location;

  const tabs: TabsProps["items"] = [
    {
      key: "",
      label: "All",
      children: InfoCollapse(`${routeParams.id}`),
    },
    {
      key: "#notes",
      label: "Notes",
      children: (
        <Notes
          id={routeParams.id}
          isHostPage={true}
          target_id={state.hostDetails.target_host?.target_id}
        />
      ),
    },
  ];

  useEffect(() => {
    if (state.reRender) {
      actions.setHostInfo(`${routeParams.id}`, state.reRender);
      actions.setRerender(false);
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.reRender]);

  useEffect(() => {
    actions.setHostInfo(`${routeParams.id}`, state.reRender);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeParams.id]);

  useEffect(() => {
    actions.setTab(hash);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  useEffect(() => {
    document.title = state.hostDetails?.target_host
      ? state.hostDetails?.target_host?.hostname +
        " / " +
        state.hostDetails.target_host?.target +
        " / BartUI"
      : document.title;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.hostDetails?.target_host?.hostname]);

  if (!token) return null;
  return (
    <div className="messages_table">
      <InfoHeader id={`${routeParams.id}`} />
      <Tabs
        style={{ color: "var(--table-text-color)", marginTop: "25px" }}
        activeKey={state.tab}
        items={tabs}
        onChange={(key) => {
          navi(key);
        }}
      />
    </div>
  );
};

export default HostInfoPage;
