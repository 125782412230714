import { Table } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie, pagination_options } from "@/shared";
import { useCMDState } from "./store";
import { get_cmd_columns } from "./ui";
import Spinner from "@/shared/components/Spinner/Spinner";

const CMDPage = () => {
  const token = getCookie("token") as string;
  const [state, actions] = useCMDState();
  const navi = useNavigate();

  useEffect(() => {
    actions.setCommands();
    document.title = "Commands / BartUI ";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !token ? (
    <></>
  ) : (
    <div className="messages_table">
      <Table
        columns={get_cmd_columns()}
        dataSource={state.commands}
        pagination={pagination_options}
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              navi(`/ui/cmd/${record.id}/info`);
            },
          };
        }}
        rowKey="id"
      />
      <Spinner loading={state.loading}/>
    </div>
  );
};

export default CMDPage;
