import { CommentOutlined } from "@ant-design/icons";
import {
  Filters,
  NetCatResponseData,
  filterData,
  onlyUnique,
  setCookie,
} from "@/shared";
import TextArea from "antd/es/input/TextArea";
import { Modal } from "antd";
import { NetcatApi } from "@/Api";
import { NetcatStore } from "../Store";

const CLIENT = new NetcatApi();

export const addComment = async (
  record: NetCatResponseData,
  setIsRerender?: (isRerender: boolean) => void
) => {
  Modal.confirm({
    icon: <CommentOutlined />,
    className: "add_server_modal",
    title: `Add comment for session`
      .concat(
        record.target_host?.hostname
          ? ` - host: ${record.target_host?.hostname} `
          : ""
      )
      .concat(
        record.target_host?.target
          ? `, target: ${record.target_host?.target}`
          : ""
      ),
    content: (
      <TextArea
        rows={8}
        id="netComentArea"
        className="coment_area"
        defaultValue={record.comment}
      />
    ),
    width: 550,
    okText: "Save",
    onCancel: () => {},
    onOk: async () => _sendComment(record, setIsRerender),
  });
};

const _sendComment = async (
  record: any,
  setIsRerender?: (isRerender: boolean) => void
) => {
  let newValue = (
    document.getElementById("netComentArea") as HTMLTextAreaElement
  )?.value;
  await CLIENT.addCommentById({
    id: record.id,
    commentText: newValue,
  });
  setIsRerender ? setIsRerender(true) : NetcatStore.actions.setTableData();
};

export const removeConnection = async (
  record: any,
  setIsRerender?: (isRerender: boolean) => void
) => {
  NetcatStore.actions.setActive(false);
  Modal.confirm({
    title: `Do you want to delete this item?  `,
    content: (
      <>
        Host name: {record.target_host.hostname} <br></br> Client IP:{" "}
        {record.client_ip}
      </>
    ),
    okText: "delete",
    okType: "danger",
    onOk: async () => {
      await CLIENT.removeById(record.id);
      if (setIsRerender) {
        setIsRerender!(true);
      } else {
        NetcatStore.actions.deleteTableRecord(record.id);
        NetcatStore.actions.setTableData();
      }
      NetcatStore.actions.setActive(true);
    },
    onCancel: () => NetcatStore.actions.setActive(true),
  });
};

export const showInfoModal = (record: NetCatResponseData) => {
  Modal.info({
    title: "Info",
    className: "manageUserModal",
    maskClosable: true,
    okText: "Close",
    content: (
      <div>
        <p>
          <b>Host name</b>: {record?.target_host.hostname}{" "}
        </p>
        <p>
          <b>Relay:</b> {record?.relay_addr}{" "}
        </p>
        <p>
          <b>Regserver:</b> {record?.registration_server_addr}{" "}
        </p>
        <p>
          <b>Client ID:</b> {record?.client_id}{" "}
        </p>
        <p>
          <b>System ID:</b> {record?.target_host.machine_id}{" "}
        </p>
        <p>
          <b>Secret: </b>
          {record?.secret}{" "}
        </p>
        <p>
          <b>Client Ips:</b>
          <TextArea
            readOnly
            autoSize
            className="info_text"
            value={record?.target_host.internal_ips
              .split(",")
              .filter(onlyUnique)
              .join("\n")}
          />
        </p>
        <p>
          <b>OS:</b> {record?.target_host.os}{" "}
        </p>
        <p>
          <b>Username:</b> {record?.target_host.username}{" "}
        </p>
        <p>
          <b>Additional:</b>
          <TextArea
            readOnly
            autoSize
            className="info_text"
            value={
              record?.additional_info
                ? JSON.stringify(record?.additional_info, null, 2)
                : ""
            }
          />
        </p>
      </div>
    ),
    onOk() {},
    width: 600,
  });
};

export const genDownloadData = () =>
  NetcatStore.storeState.getState().filteredData?.map((item) => {
    return {
      type: item.type,
      hostname: item.target_host.hostname,
      connection_string: item.connection_string,
      client_ips: item.target_host.internal_ips,
      comment: item.comment,
      client_ip: item.client_ip,
      additional_info: Buffer.from(
        JSON.stringify(item.additional_info)
      ).toString("base64"),
    };
  });

export const handleFilter = (data: NetCatResponseData[]) => {
  const filters: Filters = {
    ...NetcatStore.storeState.getState().filteredInfo,
  };
  const filteredData = filterData(data, filters);
  NetcatStore.actions.setFilteredData(filteredData as NetCatResponseData[]);
};

export const handleChange = (sorter: any, sessions: any) => {
  if (!sessions) {
    setCookie("columns_sorter", `${sorter.columnKey},${sorter.order}`, 365);
    NetcatStore.actions.changeSelectedColumnsSorting(sorter);
  }
};
