import { createHook, createStore, defaultRegistry } from "react-sweet-state";
import { ICMDState, actions } from ".";

export const initialState: ICMDState = {
  hostCommands: [],
  commands: [],
  ids: {
    host_id: undefined,
    target_id: undefined,
    cmd_id: undefined,
  },
  expandedKeys: [],
  loading: false,
};

export const Store = createStore<ICMDState, typeof actions>({
  initialState,
  actions
});

export const useCMDState = createHook(Store);

export const CMDStore = defaultRegistry.getStore(Store);
