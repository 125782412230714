import { ICMDState } from ".";
import { CmdApi } from "@/Api";
import { actionsType } from "@/shared";
const CMD_API = new CmdApi()

export const actions = {
    setHostCommands: () => async ({ setState, getState }: actionsType<ICMDState>) => {
        const state = getState()
        let hostCommands = await CMD_API.getTargetHostCmds(
            state.ids.host_id ? { target_host_id: state.ids.host_id }
                : state.ids.target_id ? { target_id: state.ids.target_id }
                    : { cmd_id: state.ids.cmd_id })
        setState({ ...getState(), hostCommands })
    },

    setCommands: () => async ({ setState, getState }: actionsType<ICMDState>) => {
        setState({ ...getState(), loading: true })
        let commands = await CMD_API.getCmdList()
        setState({ ...getState(), commands })
        setState({ ...getState(), loading: false })
    },

    clearComands: () =>
        ({ setState, getState }: actionsType<ICMDState>) => setState({ ...getState(), hostCommands: [] }),

    setIds: (ids: {}) =>
        ({ setState, getState }: actionsType<ICMDState>) => setState({ ...getState(), ids }),

    setExpandKeys: (expandedKeys: any[]) =>
        ({ setState, getState }: actionsType<ICMDState>) => setState({ ...getState(), expandedKeys }),

};
