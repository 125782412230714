import { Modal } from "antd";
import { TableDataApi } from "@/Api";
import { BinaryStore } from "../Store";

const DATA_SERVICE = new TableDataApi();
export const getTableData = async () => {
    BinaryStore.actions.setIsLoading(true);
    BinaryStore.actions.setTabledata(await DATA_SERVICE.getBinTAbleData());
    BinaryStore.actions.setIsLoading(false);
    BinaryStore.actions.setHasEmpty()
}

export const removeBinaryFile = async (id: any) => {
    Modal.confirm({
        title: "Are you sure you want to delete the file?",
        okText: "yes",
        okType: "danger",
        onOk: async () => {
            await DATA_SERVICE.removeBinaryFileById(id);
            getTableData()
        }
    })
}
