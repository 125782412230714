import {AutoComplete, Checkbox, DatePicker, Form, Input, message, Modal} from "antd";
import FormItem from "antd/es/form/FormItem";
import { useEffect, useState } from "react";
import { BeaconApi } from "@/Api";
import { useMessagesState } from "../Store";
import TextArea from "antd/es/input/TextArea";
import { EnterOutlined } from "@ant-design/icons";
import FormBtns from "./FormBtns";
import { BeaconSessions } from "./BeaconSessions";

type Props = {
  id: string;
};

const BEACON_CLIENT = new BeaconApi();

export const AddMsgModule = ({ id }: Props) => {
  const [form] = Form.useForm(undefined);
  const [state, actions] = useMessagesState();
  const [enter, setEnter] = useState(false);

  const addMessage = async () => {
    form.validateFields().then(
      async (result: any) => {
        for (let item of result.msg?.split("\n").map((item: string) => {
          return item.trim();
        })) {
          if (item.length > 0) {
            await BEACON_CLIENT.addMessage({
              id: id,
              request: item,
              recurrence_interval: result.recurrence_interval,
              detach: result.detach,
              scheduled_for: result.scheduled_for?.format(),
            });
            message.success('Command sent to beacon: ' + item);
          }
        }
        handleClear();
        form.resetFields();
        setTimeout(() => {
          actions.setBeacon(`${id}`);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 600);
      },
      (error) => {
        Modal.error({ title: "Empty message input" });
      }
    );
  };

  const handleClear = () => {
    getAutocompleteOptions();
    actions.setCommands([]);
  };

  const handleEnter = () => {
    setEnter(true);
    let items = form.getFieldValue("msg")?.split("\n");
    actions.setCommands(
      items?.filter(
        (i: any, index: number) => i.length > 0 && index !== items.length - 1
      )
    );
  };

  const getAutocompleteOptions = async () => {
    let cmds = form.getFieldValue("msg")?.split("\n");
    let response: [] = await BEACON_CLIENT.getAutocomplete({
      input: cmds?.length > 0 ? cmds[cmds.length - 1] : undefined,
    });
    actions.updateAutocomplete(response);
  };

  const filterAutocomplete = (
    inputValue: string,
    option: { value: string } | undefined
  ) => {
    let cmds = inputValue?.split("\n");
    return (
      option?.value
        .toUpperCase()
        .indexOf(cmds[cmds.length - 1].toUpperCase()) !== -1
    );
  };

  const handleSelect = (value: any) => {
    const commands = [...state.commands, value];
    actions.setCommands(commands);
    form.setFieldValue("msg", commands.join("\n"));
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      getAutocompleteOptions();
    }, 500);
    return () => {
      clearTimeout(timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldValue("msg"), state.messages]);

  useEffect(() => {
    getAutocompleteOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!enter) {
      form.setFieldValue("msg", state.commands?.join("\n"));
    }
    setEnter(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.commands]);

  return (
    <>
      <Form form={form} className="add_form">
        <FormItem
          name={"msg"}
          style={{ width: "25%", margin: "0" }}
          rules={[{ required: true }]}
        >
          <AutoComplete
            options={state.options}
            style={{ width: "100%" }}
            allowClear
            onClear={handleClear}
            filterOption={filterAutocomplete}
            onSelect={handleSelect}
          >
            <TextArea
              placeholder="add new message"
              autoSize
              className="autoCompleteTextarea"
              onChange={handleEnter}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === "Enter" && e.shiftKey) {
                  addMessage();
                }
              }}
            />
          </AutoComplete>
        </FormItem>
        <EnterOutlined
          title="Submit (Shift+Enter)"
          className="enter_btn"
          onClick={addMessage}
          onDoubleClick={() => {}}
        />
        <FormItem
          name={"recurrence_interval"}
          style={{ width: "20%", margin: "0 24px 0 20px" }}
          rules={[{ max: 5 }]}
        >
          <Input
            placeholder="add recurrence interval"
            className="autoCompleteTextarea"
            suffix={
              <EnterOutlined
                style={{
                  position: "absolute",
                  right: "-30px",
                  top: "13px",
                  cursor: "pointer",
                  fontSize: "22px",
                  color: "rgb(211, 208, 208)",
                }}
                onDoubleClick={() => {}}
                onClick={addMessage}
              />
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                addMessage();
              }
            }}
          />
        </FormItem>
        <FormItem
          label="Detach "
          name="detach"
          valuePropName="checked"
          className="detachField"
        >
          <Checkbox style={{ color: "white" }} />
        </FormItem>
        <FormItem
          label={<label className="msgFormLabel">Scheduled For</label>}
          name="scheduled_for"
          style={{
            alignItems: "center",
            alignSelf: "center",
            margin: 0,
            color: "white",
          }}
        >
          <DatePicker
            className="message_dp"
            format="DD/MM/YYYY HH:mm"
            showTime={{ use12Hours: false }}
          />
        </FormItem>
        <FormBtns id={id} />
      </Form>
      {Object.keys(state.sessions) && (
        <div className="beacon_sessions">
          <BeaconSessions
            sessions={state.sessions}
            handleStopSession={handleSelect}
          />
        </div>
      )}
    </>
  );
};
