import { DeleteOutlined } from "@ant-design/icons";
import { Modal, Tooltip } from "antd";
import { CmdApi } from "@/Api";
import { CMDStore } from "../store";

const CMD_API = new CmdApi();

export const col_actions = (record: any, isHost?: boolean) => (
  <Tooltip title="Delete" color="red">
    <DeleteOutlined
      onClick={() => {
        isHost
          ? delete_host_command(record.id, record.hostname)
          : delete_command(record.id);
      }}
      title="Delete"
      style={{ color: "red", fontSize: "19px" }}
    />
  </Tooltip>
);

const delete_command = (id: string) => {
  Modal.confirm({
    title: "Do you realy want to delete this command for all hosts?",
    onOk: async () => {
      await CMD_API.removeCmdById(id);
      CMDStore.actions.setCommands();
      CMDStore.actions.setHostCommands();
    },
  });
};

const delete_host_command = (id: string, hostname: string) => {
  Modal.confirm({
    title: `Do you realy want to delete this command for ${hostname}?`,
    onOk: async () => {
      await CMD_API.removeHostCmdById(id);
      CMDStore.actions.setCommands();
      CMDStore.actions.setHostCommands();
    },
  });
};

export const clearCmds = () => CMDStore.actions.clearComands();

export const commandStatus = (record: any): string => {
  return record?.result?.includes("ERROR")
    ? "error_com"
    : record?.processed_at
    ? "proccesed"
    : "";
};
