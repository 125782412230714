import React from 'react';
import { Patches_bins } from '@/shared';
import { Table } from 'antd';
import { patches_cols } from './columns';

type Props = {
  patched_bins: Patches_bins[]
}

export const Patches: React.FC<Props> = ({ patched_bins }) => {
  return (
    <div>
      <Table
        className='credsTable'
        columns={patches_cols}
        dataSource={patched_bins}
        pagination={false}
        rowKey='id' />
    </div>
  )
}

