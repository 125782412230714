import { Tooltip } from "antd";
import {getFilterOptions, onlyUnique, defaultFilter} from "@/shared";
import { FileZipOutlined } from "@ant-design/icons";
import { BeaconTypeIcon } from "..";

export const renderBeaconsIcons = (beacons: string[]) =>
  beacons?.map((item, index) => BeaconTypeIcon(item, index));

export const iconFilter = (tabledata: any[]) => {
  if (tabledata) {
    let beacons = tabledata
      .map((x) => x.beacons)
      .reduce((x: string[], y: string[]) => x.concat(y), [])
      .filter(onlyUnique);
    let sessions = tabledata
      .map((x) => x.remote_sessions)
      .reduce((x: string[], y: string[]) => x.concat(y), [])
      .filter(onlyUnique);
    return getFilterOptions([...defaultFilter, ...beacons.concat(sessions)]);
  }
};

export const renderTools = (
  beacons: string[],
  sessions: string[],
  collection_results?: boolean
) => {
  return (
    <span
      style={{ display: "flex", flexWrap: "wrap" }}
      key={new Date().getTime() + 100}
    >
      {renderBeaconsIcons(beacons)}
      {renderBeaconsIcons(sessions)}
      {collection_results && (
        <Tooltip title="collection results ready">
          <FileZipOutlined
            style={{
              color: "green",
              fontSize: "19px",
              alignSelf: "center",
            }}
          />
        </Tooltip>
      )}
    </span>
  );
};
