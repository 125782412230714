import React from 'react';
import {TargetNote, renderHostLinks} from '@/shared';
import { Card, Empty, Tooltip } from 'antd';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import cls from '../notes.module.css';
import moment from 'moment';
import {TargetApi} from "@/Api";

const TARGET_CLIENT = new TargetApi();


type Props = {
    id: string | undefined,
    notes: TargetNote[],
    deleteNote: (item: TargetNote) => void
}

const NodeCards: React.FC<Props> = ({ id, notes, deleteNote }) => {
    return <>{notes.length > 0 ? notes.map((i, index) => {
        return <Card key={index}
            title={<>
                <a href={`/ui/target/${id}/note/${i.id}`} 
                    className={cls.titleLink}>{i.title}</a>
                <Tooltip title="Delete note" color='red'>
                    <DeleteOutlined onClick={() => { deleteNote(i) }} className={`${cls.actionBtn} ${cls.deleteBtn}`} />
                </Tooltip>
                <Tooltip title="Export note as .txt" color='darkblue'>
                    <DownloadOutlined className={`${cls.actionBtn} ${cls.downloadBtn}`}
                     onClick={() => TARGET_CLIENT.downloadNoteFile(id!, i)}
                    />
                </Tooltip>
            </>} className={cls.noteCard} bordered={true}
            style={{ width: 400, margin: '10px' }}>
                <><b>Last updated by:</b> {i.user} </><br />
                <><b>Version:</b> {i.version} </><br />
                <><b>Created:</b> {moment(i.created_at).format('MMM DD YYYY, HH:mm')} 
                </><br />
                <><b>Updated:</b> {moment(i.updated_at).format('MMM DD YYYY, HH:mm')} 
                </><br />
                <><b>Hosts:</b>&nbsp;{i.hosts.length > 0 ? renderHostLinks(i.hosts) : 'There are no hosts'}
                </>
                { i.file_url &&  (
                    <><br /><b> Content file:</b>&nbsp;
                    <a href={i.file_url} target="_blank" rel="noopener noreferrer">
                        {i.file_name}
                    </a>
                    </>
                )}                
        </Card>
    }) : <div className={cls.nodesList}> 
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
    }</>
}

export default NodeCards;
