import MDEditor from "@uiw/react-md-editor";
import { Modal, message } from "antd";
import { ExpandAltOutlined } from "@ant-design/icons";
import { BeaconApi, TargetApi } from "@/Api";
import { MsgStore } from "../Store";
import { BeaconMessage } from "@/shared";
import { getSessionsWrapper } from ".";

const BEACON_CLIENT = new BeaconApi();
const TARGET_CLIENT = new TargetApi();

type helpCommand = {
  name: string;
  help: string;
};

const showHelpModal = (text: helpCommand[]) => {
  Modal.info({
    icon: null,
    content:
      typeof text === "string" ? (
        <MDEditor
          className="markdown"
          preview="preview"
          value={text}
          hideToolbar={true}
        />
      ) : (
        text.map((item: helpCommand) => {
          return (
            <div key={item.name} className="help_item">
              <details key={item.name}>
                <summary>
                  <h2>
                    {item.name} <ExpandAltOutlined />
                  </h2>
                </summary>
                <MDEditor
                  preview="preview"
                  className="markdown"
                  hideToolbar={true}
                  value={`\`\`\`\n${item.help}\`\`\``}
                />
              </details>
            </div>
          );
        })
      ),
    width: 900,
    maskClosable: true,
    style: { height: "75vh" },
  });
};

export const showHelp = () => {
  BEACON_CLIENT.getHelp(MsgStore.storeState.getState().beacon!.type).then(
    (res: helpCommand[]) => {
      showHelpModal(res);
    }
  );
};

export const countRows = (str: string) => {
  let num = str.match(new RegExp("\n", "g"))?.length; // eslint-disable-line
  return num ? num + 1 : str.length > 0 ? 1 : 0;
};

export const deletemessage = async (params: BeaconMessage) => {
  await BEACON_CLIENT.deleteMessages(params);
  MsgStore.actions.setMessages([
    ...MsgStore.storeState
      .getState()
      .messages.filter((item) => item.id !== params.id),
  ]);
  MsgStore.actions.setBeacon(MsgStore.storeState.getState().beacon!.id);
  message.success('Command deleted: ' + params.request);
};

export const updateMsgsInfo = () => {
  const state = MsgStore.storeState.getState();
  state.msgsInProgress?.forEach(async (item: BeaconMessage) => {
    let resp: BeaconMessage = await BEACON_CLIENT.getMesagesUpdates({
      beacon_id: item.beacon_id,
      message_id: item.id,
    });

    if (resp.processed_at) {
      MsgStore.actions.setMessages([
        ...state.messages?.map((item) => (item.id === resp.id ? resp : item)),
      ]);
      MsgStore.actions.setMsgsInProgress([
        ...state.msgsInProgress?.filter((item) => item.id !== resp.id),
      ]);
      getSessionsWrapper(resp.request);
    }
  });
};

export const download = (filename: string, text: string) => {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const createTargetNote = (target_id: string, title: string, content: string, host_id: string) => {
  let note: any = {
    content: content,
    hosts_ids: [host_id],
    title: title,
  };
  TARGET_CLIENT.createNote({
    id: target_id,
    data: note,
  }).then(
    (result) => {
      message.success( (
        <span>Note successfully added: <a
          href={`/ui/target/${target_id}/note/${result.id}`} target="_blank" rel="noopener noreferrer"
        >
          {title}
        </a>
        </span>
      ));
    },
    (error) => {
      message.error(error);
    }
  );
};

