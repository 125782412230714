
const internetIcon = require('@/images/Internet-icon.png')
const gatewayIcon = require('@/images/gateway.png')
const linuxIcon = require('@/images/Linux.png')
const windIcon = require('@/images/Windows.png')
const darwinIcon = require('@/images/darwin.png')


type NodeType = 'gateway' | 'host' | 'public-net' | 'private-net' | unknown;

export const NodeIcon = (type: NodeType, os?: string) => {
    switch (type) {
        case 'gateway': return gatewayIcon;
        case 'host': return os === 'windows' ? windIcon : os === 'linux' ? linuxIcon : os === 'darwin' ? darwinIcon : '';
        case 'public-net': return internetIcon;
        case 'private-net': return internetIcon;
        default: return '';
    }
}
