import { useEffect } from "react";
import { AuthorizeApi } from "@/Api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { clearCacheData, getCookie } from "@/shared";
import { useAdminState } from "../AdminPage/Store";

type Props = {
  response?: string;
};

let authService = new AuthorizeApi();
const OAuth = (props: Props) => {
  const [, actions] = useAdminState(); // eslint-disable-line
  const navi = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
  const getToken = async () => {
    let response = await authService.postAuthentic({
      code: searchParams.get("code"),
      state: searchParams.get("state"),
    });
    const token = getCookie("token") as string;
    actions.setCurrentUser(response);
    token && clearCacheData();
    token ? navi("/ui/home") : navi("/");
  };

  useEffect(() => {
    getToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
};

export default OAuth;
