import { Table } from "antd";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { pagination_options } from "@/shared";
import { Rollback } from "../shared";
import { clearCmds, commandStatus } from "./actions/actions";
import { useCMDState } from "./store";
import { defaultExpandable, get_cmdinfo_columns } from "./ui";
import "./styles.css";

type Props = {
  host_id?: string;
  target_id?: string;
};

const HostCmdInfo = ({ host_id, target_id }: Props) => {
  const [state, actions] = useCMDState();
  const routeParams = useParams();

  useEffect(() => {
    actions.setIds({
      host_id: host_id,
      target_id: target_id,
      cmd_id: routeParams?.id,
    });

    actions.setHostCommands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeParams]);

  return (
    <div className={host_id || target_id ? "" : "messages_table"}>
      {host_id || target_id ? (
        <></>
      ) : (
        <Rollback
          title={"back to commands list"}
          delta={-1}
          customFn={() => clearCmds}
        />
      )}
      <Table
        className="cmd_info"
        expandable={defaultExpandable()}
        columns={get_cmdinfo_columns()}
        dataSource={state.hostCommands}
        pagination={pagination_options}
        rowClassName={commandStatus}
        rowKey="id"
      />
    </div>
  );
};

export default HostCmdInfo;
