import {
  CredentialModel,
  Tabledata,
  NetCatResponseData,
  Beacon,
  Connection_paths,
  Host,
  Patches_bins,
  collection_result_analysis,
  TargetModel,
} from "..";
import { AlertLevel } from "@/Components/TargetHosts/ui/hostAlert";

export class HostInfo {
  beacons: Beacon[];
  collection_results: Tabledata[];
  remote_sessions: NetCatResponseData[];
  credentials: CredentialModel[];
  target_host?: Host;
  patched_bins: Patches_bins[];
  connection_paths: Connection_paths[];
  collection_result_analysis: collection_result_analysis[];
  target?: TargetModel;
  alert_level_history?: AlertLevelHistory[];
  shollector_results?: ShollectorResult[];

  constructor() {
    this.beacons = [];
    this.collection_results = [];
    this.remote_sessions = [];
    this.credentials = [];
    this.patched_bins = [];
    this.connection_paths = [];
    this.collection_result_analysis = [];
  }
}

type AlertLevelHistory = {
  alert_level: AlertLevel;
  reason: string;
  created_at: Date;
  created_by: string;
};

export type ShollectorResult = {
  content: string;
  created_at: Date;
  updated_at: Date;
};
