import { BeaconApi } from "@/Api";
import { Tooltip, Upload } from "antd";
import { QuestionCircleFilled, UploadOutlined } from "@ant-design/icons";
import { showHelp } from "../actions";
import { Rollback } from "@/Components/shared";
import { useMessagesState } from "../Store";

const BEACON_CLIENT = new BeaconApi();
type Props = { id: string };

const FormBtns = ({ id }: Props) => {
  const [, actions] = useMessagesState();
  const uploadFile = async (componentsData: any) => {
    let formData = new FormData();
    formData.set("file", componentsData.file);
    await BEACON_CLIENT.uploadFile(`${id}`, formData);
  };

  const uploadProps = {
    accept: "*",
    multiple: true,
    multipart: true,
    customRequest: uploadFile,
    showUploadList: false,
  };

  return (
    <>
      {" "}
      <Tooltip title={"Upload file"} color="#cf8d99">
        <Upload {...uploadProps}>
          <UploadOutlined className="upload_btn" />
        </Upload>
      </Tooltip>
      <Tooltip title={"Help"} color="green">
        <QuestionCircleFilled
          style={{
            cursor: "pointer",
            color: "green",
            marginLeft: "10px",
            fontSize: "25px",
          }}
          onClick={showHelp}
        />
      </Tooltip>
      <Rollback
        customFn={() => {
          actions.setCommands([]);
        }}
        title="back to beacon list"
        delta={-1}
      />
    </>
  );
};

export default FormBtns;
