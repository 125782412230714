import AuthLocalStorage from "@/AuthLocalStorage";
import { actionsType, themes } from "@/shared";
import { IMenuState } from "./IMenuState";


export const actions = {
    initTheme: () =>
        ({ setState, getState }: actionsType<IMenuState>) => {
            const theme = AuthLocalStorage.getTheme() ? AuthLocalStorage.getTheme() as string : 'light';
            document.body.classList.add(theme)
            setState({ ...getState(), theme })
        },

    changeTheme: () =>
        ({ setState, getState }: actionsType<IMenuState>) => {
            let th = AuthLocalStorage.getTheme() as string;
            let theme = themes[themes.indexOf(themes.find((i) => i === th)!) + 1 === themes.length ? 0
                : themes.indexOf(themes.find((i) => i === th)!) + 1];
            AuthLocalStorage.setTheme(theme)
            document.body.classList.remove(th)
            document.body.classList.add(theme)
            setState({ ...getState(), theme })
        },

    setKey: (key: string) =>
        ({ setState, getState }: actionsType<IMenuState>) => setState({ ...getState(), key }),

    setSelectedKeys: () =>
        ({ setState, getState }: actionsType<IMenuState>) => {
            let selectedKeys = window.location.pathname.includes('messages') ?
                ['/ui/beacon'] : window.location.pathname.includes('host') ?
                    ['/ui/target-host'] : window.location.pathname.includes('/target/') ?
                        ['/ui/targets'] : [window.location.pathname]
            setState({ ...getState(), selectedKeys })
        },

};
