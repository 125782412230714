import { DateCorrector, ServerToken, getFilterOptions, onlyUnique, sortOptions } from "@/shared";
import { ColumnsType } from "antd/es/table";
import { CheckOutlined, FileAddOutlined } from "@ant-design/icons";

const get_token_columns = (render_actions: (record: any) => JSX.Element, addNewServer: () => void, tableData: ServerToken[]) => [
  {
    title: "Server",
    dataIndex: "server",
    key: "server",
    filters: sortOptions(getFilterOptions(tableData?.map(x => x.server)?.filter(onlyUnique))),
    filterSearch: true,
    onFilter: (value: any, record: any) => record.server.includes(value),
  },
  {
    title: "User",
    dataIndex: "user",
    key: "user",
    filters: sortOptions(getFilterOptions(tableData?.map(x => x.user)?.filter(onlyUnique))),
    filterSearch: true,
    onFilter: (value: any, record: any) => record.user.includes(value),
  },
  {
    title: "Active",
    dataIndex: "active",
    key: "active",
    render: (active: boolean) => active && <CheckOutlined />
  },
  {
    title: "Tags",
    dataIndex: "tags",
    key: "tags",
    filters: sortOptions(getFilterOptions(tableData?.flatMap(x => x.tags)?.filter(onlyUnique))),
    filterSearch: true,
    render: (tags: string[]) => tags?.toString(),
    onFilter: (value: any, record: any) => record.tags?.includes(value),
  },

  {
    title: "Created",
    dataIndex: "created_at",
    key: "created_at",
    render: (created_at: any) => { return <DateCorrector date={new Date(created_at)} /> },
    sorter: (a: { created_at: any }, b: { created_at: any }) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
  },
  {
    title: "Last used at",
    dataIndex: "last_used_at",
    key: "last_used_at",
    render: (last_used_at: any) => { return last_used_at ? <DateCorrector date={new Date(last_used_at)} /> : <></> },
    sorter: (a: { last_used_at: any }, b: { last_used_at: any }) => new Date(a.last_used_at).getTime() - new Date(b.last_used_at).getTime(),
  },
  {
    title: "Expires",
    dataIndex: "expires_at",
    key: "expires_at",
    render: (expires_at: any) => { return <>{Math.round((new Date(expires_at).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))} days</> },
    sorter: (a: { expires_at: any }, b: { expires_at: any }) => new Date(a.expires_at).getTime() - new Date(b.expires_at).getTime(),
  },
  {
    title: (<>Actions  <FileAddOutlined className='title_btns' title='Add new server' style={{ color: 'green', marginLeft: '20%' }} onClick={addNewServer} /></>),
    dataIndex: "Actions",
    key: "Actions",
    render: (_text: any, record: any) => render_actions(record),
    width: 120,
    fixed: 'right',
  }
] as ColumnsType<any>

export default get_token_columns



