import { AxiosError } from "axios";
import { handleError } from "@/shared";
import { Api } from ".";

export class NetcatApi {
  getData = async (data: any) => {
    try {
      const response = await Api.post("/api/v1/session/list", data)
        .then((response: { data: any }) => {
          return response.data;
        });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  }

  getSessionsTypes = async () => {
    try {
      const response = await Api.get("/api/v1/session/type/list")
        .then((response: { data: any }) => {
          return response.data;
        });
      return response;

    } catch (error) {
      handleError(error as AxiosError);
    }
  }

  addCommentById = async (params: any) => {
    try {
      const response = await Api.put(`/api/v1/session/${params.id}`, { comment: params.commentText })
        .then((response: { data: any }) => {
          return response.data;
        });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  }

  removeById = async (params: any) => {
    try {
      const response = await Api.remove(`/api/v1/session/${params}`)
        .then((response) => {
          return response.data;
        });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  }
}
