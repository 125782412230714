import { actionsType } from "@/shared";
import { IBinaryState } from "./IBinaryState";

export const actions = {
  setTabledata:
    (tabledata: any[]) =>
    ({ setState, getState }: actionsType<IBinaryState>) =>
      setState({ ...getState(), tabledata }),

  setIsHelpModal:
    (isHelpModal: boolean) =>
    ({ setState, getState }: actionsType<IBinaryState>) =>
      setState({ ...getState(), isHelpModal }),

  setHelp:
    (help: string) =>
    ({ setState, getState }: actionsType<IBinaryState>) =>
      setState({ ...getState(), help }),

  setBBOptions:
    (bbOptions: string[]) =>
    ({ setState, getState }: actionsType<IBinaryState>) =>
      setState({ ...getState(), bbOptions }),

  setChekedValues:
    (values: string[]) =>
    ({ setState, getState }: actionsType<IBinaryState>) => {
      let state = getState();
      let checkedValues =
        state.checkedValues.length === state.bbOptions.length &&
        values.includes("all")
          ? []
          : values.includes("all")
          ? state.bbOptions
          : values;
      setState({ ...getState(), checkedValues });
    },

  setChekedKind:
    (checkedKind: string[]) =>
    ({ setState, getState }: actionsType<IBinaryState>) =>
      setState({ ...getState(), checkedKind }),

  setHasEmpty:
    () =>
    ({ setState, getState }: actionsType<IBinaryState>) => {
      const hasEmpty = getState()?.tabledata?.some((item: any) => {
        return item.result.length === 0;
      });
      setState({ ...getState(), hasEmpty });
    },

  setIsFormOpen:
    (isFormOpen: boolean) =>
    ({ setState, getState }: actionsType<IBinaryState>) =>
      setState({ ...getState(), isFormOpen }),

  setShowAdvanced:
    (showAdvanced: boolean) =>
    ({ setState, getState }: actionsType<IBinaryState>) =>
      setState({ ...getState(), showAdvanced }),

  setIsBB:
    (isBb: boolean) =>
    ({ setState, getState }: actionsType<IBinaryState>) =>
      setState({ ...getState(), isBb }),

  setIsLoading:
    (isLoading: boolean) =>
    ({ setState, getState }: actionsType<IBinaryState>) =>
      setState({ ...getState(), isLoading }),

  clearStates:
    () =>
    ({ setState, getState }: actionsType<IBinaryState>) => {
      setState({
        ...getState(),
        checkedValues: [],
        isLoading: false,
        isBb: false,
        isFormOpen: false,
        checkedKind: ["bb"],
        showAdvanced: false,
      });
    },
};
