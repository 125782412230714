import { AxiosError } from "axios";
import { handleError } from "@/shared";
import { Api } from ".";

export class CmdApi {
  addCmdsToHosts = async (data: any) => {
    try {
      const response = await Api.post("/api/v1/cmd", data)
        .then((response: { data: any }) => {
          return response.data;
        })
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  }

  getCmdList = async () => {
    try {
      const response = await Api.get("/api/v1/cmd/list")
        .then((response: { data: any }) => {
          return response.data;
        })
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  }

  getTargetHostCmds = async (data: any) => {
    try {
      const response = await Api.post("/api/v1/target-host/cmd/list", data)
        .then((response: { data: any }) => {
          return response.data;
        })
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  }

  removeCmdById = async (id: string) => {
    try {
      const response = await Api.remove(`/api/v1/cmd/${id}`)
        .then((response: { data: any }) => {
          return response.data;
        })
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  }

  removeHostCmdById = async (id: string) => {
    try {
      const response = await Api.remove(`/api/v1/target-host/cmd/${id}`)
        .then((response: { data: any }) => {
          return response.data;
        })
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  }



}
