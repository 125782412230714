import { DiffOutlined } from "@ant-design/icons";
import { Form, message, Modal, Radio, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { TargetApi } from "@/Api";
import { TargetNote, setCookie } from "@/shared";
import AddNoteForm from "./Modules/AddNoteForm";
import EditFormBtns from "./Modules/EditFormBtns";
import NodeCards from "./Modules/NodeCards";
import NodesList from "./Modules/NodesList";
import cls from "./notes.module.css";
import { useTargetState } from "../TargetsPage/Store";

type Props = {
  id: string | undefined;
  isHostPage?: boolean;
  target_id?: string;
};

const TARGET_CLIENT = new TargetApi();

const Notes: React.FC<Props> = ({ id, isHostPage, target_id }) => {
  const [state, actions] = useTargetState();
  const [form] = Form.useForm(undefined);
  const { hash } = window.location;
  const [addedNote, setAddedNote] = useState(undefined);

  const getData = async () => {
    isHostPage
      ? actions.setNotes(await TARGET_CLIENT.getHostNotes(`${id}`))
      : actions.setNotes(await TARGET_CLIENT.getNotes(`${id}`));
  };

  const deleteNote = (item: TargetNote) => {
    Modal.confirm({
      title: "Do you really want to delete this Note?",
      onOk: async () => {
        await TARGET_CLIENT.deleteNote(`${target_id}`, `${item.id}`);
        getData();
      },
    });
  };

  const checkClose = () => {
    if (
      state.isDirtyNote &&
      window.confirm(
        "The page has unsaved data. Are you sure you want to close form?"
      )
    ) {
      actions.setIsEdit(false);
      actions.setIsDirtyNote(false);
    } else if (!state.isDirtyNote) {
      actions.setIsEdit(false);
    }
  };

  const addNewNote = () => {
    form.validateFields().then(
      async (result) => {
        let note: any = {
          content: result.content,
          hosts_ids: result.hosts,
          title: result.title,
          file_path: result.file_path,
          file_name: result.file_name,
        };
        note.version =
          addedNote && state.notes.find((i) => i.id === addedNote)!.version + 1;

        addedNote
          ? await TARGET_CLIENT.updateNote({
              target_id: id!,
              note_id: addedNote,
              data: note,
            }).then(
              (result) => {
                message.success("Note updated");
              },
              (error) => {
                message.error(error);
              }
            )
          : await TARGET_CLIENT.createNote({
              id: id!,
              data: note,
            }).then(
              (result) => {
                setAddedNote(result.id);
                message.success("Note successfully added");
              },
              (error) => {
                message.error(error);
              }
            );
        actions.setIsDirtyNote(false);
        getData();
      },
      (error: any) => {
        console.log(error);
      }
    );
  };

  const saveAndExit = () => {
    form.validateFields().then(
      async (result) => {
        await TARGET_CLIENT.createNote({
          id: id!,
          data: {
            content: result.content,
            hosts_ids: result.hosts,
            title: result.title,
            file_path: result.file_path,
            file_name: result.file_name,
          },
        });
        actions.setIsDirtyNote(false);
        getData();
        actions.setIsEdit(false);
        form.resetFields();
      },
      (error: any) => {
        console.log(error);
      }
    );
  };

  const onListChange = (e: any) => {
    setCookie("listStyle", e.target.value, 365);
    actions.setListStyle(e.target.value);
  };

  const handleUnload = (e: any) => {
    e.preventDefault();
    return "The page has unsaved data. Are you sure you want to navigate away?";
  };

  useEffect(() => {
    if (!state.isDirtyNote) return;
    window.addEventListener("beforeunload", handleUnload, { capture: false });
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [state.isDirtyNote]);

  useEffect(() => {
    (hash.includes("notes") || id !== undefined) && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash, id]);

  useEffect(() => {
    !state.isEdit && form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isEdit]);

  return (
    <div className={cls.notes}>
      {!state.isEdit && (
        <Radio.Group
          style={{ marginLeft: "10px", marginBottom: "10px" }}
          onChange={onListChange}
          value={state.listStyle}
        >
          <Radio value={"List"}>Notes list</Radio>
          <Radio value={"Cards"}>Notes cards</Radio>
        </Radio.Group>
      )}
      {!isHostPage ? (
        !state.isEdit ? (
          <Tooltip title="Add new note" color="green">
            <DiffOutlined
              className={cls.addNewBtn}
              onClick={() => {
                actions.setIsEdit(true);
              }}
            />
          </Tooltip>
        ) : (
          <EditFormBtns
            addNewNote={addNewNote}
            saveAndExit={saveAndExit}
            checkClose={checkClose}
          />
        )
      ) : (
        <></>
      )}
      <div className={cls.cards_block}>
        {!state.isEdit ? (
          state.listStyle === "Cards" ? (
            <NodeCards
              id={isHostPage ? target_id : id}
              notes={state.notes}
              deleteNote={deleteNote}
            />
          ) : (
            <NodesList
              id={isHostPage ? target_id : id}
              notes={state.notes}
              deleteNote={deleteNote}
            />
          )
        ) : (
          <AddNoteForm
            form={form}
            setIsDirty={actions.setIsDirtyNote}
            id={isHostPage ? target_id : id}
          />
        )}
      </div>
    </div>
  );
};

export default Notes;
