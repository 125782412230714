import { Form, Input, Modal, Select, Table } from "antd";
import FormItem from "antd/es/form/FormItem";
import {
  DateCorrector,
  getFilterOptions,
  getOptions,
  onlyUnique,
  sortOptions,
} from "@/shared";
import { useHostState } from "../store";
import { TargetApi } from "@/Api";
import { useEffect } from "react";
import { useTargetHostState } from "../../Store";

const TARGET_CLIENT = new TargetApi();

const ChangeAlertModal = ({ id }: { id: string }) => {
  const [form] = Form.useForm(undefined);
  const [state, actions] = useHostState();

  const [, hostActions] = useTargetHostState();

  const submit = () => {
    form.validateFields().then(
      async (result) => {
        await TARGET_CLIENT.updateHostAlert(id, result);
        actions.setHostInfo(id, true);
        clear();
      },
      (error) => console.error(error)
    );
  };

  const clear = () => {
    actions.setChangeAlert(false);
    hostActions.setAlertId(undefined);
    form.resetFields();
  };

  const cols = [
    {
      title: "alert_level",
      dataIndex: "alert_level",
      key: "alert_level",
    },
    {
      title: "reason",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "created_at",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at: any) => (
        <DateCorrector date={new Date(created_at)} />
      ),
    },
    {
      title: "created_by",
      dataIndex: "created_by",
      key: "created_by",
      filters: sortOptions(
        getFilterOptions(
          state?.hostDetails?.alert_level_history
            ?.map((x) => x.created_by)
            ?.filter(onlyUnique)!
        )
      ),
      filterSearch: true,
      onFilter: (value: any, record: any) => record.created_by?.includes(value),
    },
  ];

  useEffect(() => {
    if (
      state?.hostDetails?.target_host?.alert_level &&
      state.hostDetails.target_host.id === id &&
      state.change_alert
    ) {
      form.setFieldValue(
        "alert_level",
        state?.hostDetails?.target_host?.alert_level
      );
    } else if (state.change_alert) {
      actions.setHostInfo(id, false);

      form.setFieldValue(
        "alert_level",
        state?.hostDetails?.target_host?.alert_level
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.hostDetails?.target_host?.alert_level, state.change_alert]);

  return (
    <Modal
      width={"700px"}
      title="Change alert level"
      open={state.change_alert}
      onOk={submit}
      onCancel={clear}
      afterClose={clear}
    >
      <Form layout="vertical" form={form} className="addcmd_form">
        <FormItem
          label="Alert level"
          name={"alert_level"}
          rules={[{ required: true }]}
        >
          <Select
            value={state?.hostDetails?.target_host?.alert_level}
            className="alertSelect"
            placeholder="Enter alert level"
            showSearch={true}
            options={getOptions(["red", "green", "yellow"])}
          />
        </FormItem>
        <FormItem
          label="Reason"
          name={"reason"}
          className="reason"
          rules={[{ required: true }]}
        >
          <Input style={{ width: "100%" }} placeholder="Enter command" />
        </FormItem>
      </Form>
      <label className="target_label">Alert level history: </label>
      <Table
        columns={cols}
        rowKey="created_at"
        dataSource={state?.hostDetails?.alert_level_history}
        pagination={{
          defaultPageSize: 5,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "15", "20"],
        }}
      />
    </Modal>
  );
};

export default ChangeAlertModal;
