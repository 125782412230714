import { Form, Modal, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import Graph, { MultiGraph } from "graphology";
import forceAtlas2 from "graphology-layout-forceatlas2";
import circular from "graphology-layout/circular";
import { Attributes } from "graphology-types";
import { useEffect, useState } from "react";
import { indexParallelEdgesIndex } from "@sigma/edge-curve";
import Sigma from "sigma";
import { NodeImageProgram } from "@sigma/node-image";
import { Coordinates, EdgeDisplayData, NodeDisplayData } from "sigma/types";
import { TargetApi } from "@/Api";
import { SigmaState, getOptions, onlyUnique } from "@/shared";
import { Toggle } from "../../../shared";
import { InfoDrawer, Legend, NodeIcon } from "../../ui";
import { useMapState } from "./Store";
import "./graphstyles.css";

const graph: Graph = new MultiGraph();

const TARGET_CLIENT = new TargetApi();

const sigmaState: SigmaState = {
  searchQuery: "",
  searchRoutesQuery: "",
};

type Props = {
  id: string | undefined;
};

const NetworkGraph: React.FC<Props> = ({ id }) => {
  const [form] = useForm(undefined);
  const [state, actions] = useMapState();
  const [renderer, setRenderer] = useState<Sigma>();

  const setData = async () => {
    actions.setIsLoading(true);

    let response = await TARGET_CLIENT.getNetworkMap(`${id}`, {
      gateways: state.isGateways,
      routes: state.isRoutes,
      iedges: state.i_edges,
      processes: state.processes,
    });

    actions.setGraphData({
      nodes: response?.nodes
        ? response?.nodes?.map((i: any) => setNodeParams(i))
        : [],
      edges: response?.edges ? response.edges : [],
    });

    if (!response?.nodes && !response?.nodes) {
      showNoNodeModal();
    }
    actions.setIsLoading(false);
  };

  const setNodeParams = (node: any) => {
    node.color =
      node.access === "lost"
        ? "red"
        : node.type === "process"
        ? "purple"
        : (node.type === "host" && node.access === "root") ||
          (node.type === "gateway" && node.access !== "none")
        ? "green"
        : node.access === "none"
        ? "grey"
        : (node.type === "host" && node.access === "user") ||
          (node.type !== "host" && node.type !== "private-net")
        ? "yellow"
        : node.access === "unknown"
        ? "#a9ee09"
        : "aqua";
    node.size =
      node.type === "gateway"
        ? 12
        : node.color === "green"
        ? 10
        : node.color === "yellow"
        ? 9
        : node.color === "aqua"
        ? 12
        : node.color === "purple"
        ? 5
        : 7;
    return node;
  };

  const getEdgeColor = (type: string) => {
    switch (type) {
      case "interface":
        return "green";
      case "route":
        return "purple";
      case "gw-route":
        return "green";
      case "gw-route-src":
        return "blue";
      case "gw-route-dst":
        return "red";
      default:
        return "grey";
    }
  };

  const addNodes = () => {
    graph.clear();
    graph.removeAllListeners();

    renderer && renderer.refresh();
    state.graphData.nodes?.forEach((line) => {
      let item = {
        id: line.id,
        label: line.name,
        size: line.size,
        color: line.color,
        node_type: line.type,
        access: line.access,
        addresses: line.addresses,
        routes: line.routes,
        type: "image",
        image: NodeIcon(line.type, line.os),
        processes: [""],
      };
      if (line.processes) {
        item.processes = line.processes;
      }
      graph.addNode(line.id, item);
    });
  };

  const getEdgeSize = (type: string) => {
    switch (type) {
      case "interface":
        return 2;
      case "process":
        return 2;
      case "gw-route":
        return 2;
      default:
        return 3;
    }
  };

  const addEdges = () => {
    state.graphData.edges.forEach((line) => {
      graph.addEdge(line.source, line.destination, {
        label: line.name,
        dest: line.destination,
        color: getEdgeColor(line.type),
        srs: line.source,
        size: getEdgeSize(line.type),
        type:
          line.type === "process" ||
          line.type === "interface" ||
          line.type === "gw-route"
            ? "line"
            : "arrow",
      });
    });
  };

  const setSearchQuery = (query: string) => {
    actions.setGraphState({ ...state.graphState, searchQuery: query });

    if (query) {
      const lcQuery = query.toLowerCase();
      const suggestions = graph
        .nodes()
        .map((n) => {
          let items = [
            {
              id: n,
              label: graph.getNodeAttribute(n, "label") as string,
            },
          ];
          graph.getNodeAttribute(n, "addresses")?.forEach((element: any) => {
            items.push({
              id: n,
              label: element as string,
            });
          });
          graph.getNodeAttribute(n, "routes")?.forEach((element: any) => {
            items.push({
              id: n,
              label: element as string,
            });
          });

          return items;
        })
        .flatMap((i) => i)

        .filter(({ label }) => label?.toLowerCase().includes(lcQuery));

      if (suggestions.length === 1 && suggestions[0].label === query) {
        actions.setGraphState({
          ...state.graphState,
          selectedNode: suggestions[0].id,
          suggestions: undefined,
        });

        sigmaState.selectedNode = suggestions[0].id;
        sigmaState.suggestions = undefined;
        const nodePosition = renderer?.getNodeDisplayData(
          suggestions[0].id
        ) as Coordinates;
        renderer?.getCamera().animate(nodePosition, {
          duration: 500,
        });
        setHoveredNode(suggestions[0].id);
      } else {
        actions.setGraphState({
          ...state.graphState,
          selectedNode: undefined,
          suggestions: new Set(suggestions.map(({ id }) => id)),
        });
        sigmaState.selectedNode = undefined;
        sigmaState.suggestions = new Set(suggestions.map(({ id }) => id));
      }
    } else {
      actions.setGraphState({
        ...state.graphState,
        selectedNode: undefined,
        suggestions: undefined,
      });
      sigmaState.selectedNode = undefined;
      sigmaState.suggestions = undefined;
    }
  };

  const clearSearch = () => {
    sigmaState.searchQuery = "";
    sigmaState.searchRoutesQuery = "";
    sigmaState.selectedNode = undefined;
    sigmaState.suggestions = undefined;
    sigmaState.hoveredNeighbors = undefined;
    sigmaState.hoveredNode = undefined;
    actions.setGraphState({
      hoveredNode: undefined,
      selectedNode: undefined,
      suggestions: undefined,
      hoveredNeighbors: undefined,
      searchQuery: "",
      searchRoutesQuery: "",
    });
  };

  const nodeClick = (node: string) => {
    if (state.nodeInfo) {
      actions.setNodeInfo(undefined);
    }
    let attr = graph.getNodeAttributes(node);
    let edgesSors: Attributes[] = [];
    let edgesDestinations: Attributes[] = [];
    graph.edges().forEach((i) => {
      let edgesAttr = graph.getEdgeAttributes(i);
      if (edgesAttr.srs === node) {
        edgesSors.push(edgesAttr);
      }
      if (edgesAttr.dest === node) {
        edgesDestinations.push(edgesAttr);
      }
    });

    actions.setNodeInfo({
      id: attr["id"],
      name: attr["label"],
      edgesSrs: edgesSors
        .map((i) => i.label)
        .sort()
        .join("\n"),
      edgesDest: edgesDestinations
        .map((i) => i.label)
        .sort()
        .join("\n"),
      node_type: attr["node_type"],
      access: attr["access"],
      processes: attr["processes"]?.sort().join("\n"),
      addresses: attr["addresses"]?.sort().join("\n"),
      routes: attr["routes"]?.sort().join("\n"),
    });
    actions.setIsDrawerOpen(true);
  };

  const setHoveredNode = (node?: string) => {
    if (node) {
      actions.setGraphState({
        ...state.graphState,
        hoveredNode: node,
        hoveredNeighbors: new Set(graph.neighbors(node)),
      });
      sigmaState.hoveredNode = node;
      sigmaState.hoveredNeighbors = new Set(graph.neighbors(node));
    } else {
      actions.setGraphState({
        ...state.graphState,
        hoveredNode: undefined,
        hoveredNeighbors: undefined,
      });
      sigmaState.hoveredNode = undefined;
      sigmaState.hoveredNeighbors = undefined;
    }
  };

  const setRendererSettings = (renderer: Sigma) => {
    renderer.on("enterNode", ({ node }) => {
      setHoveredNode(node);
    });

    renderer.on("leaveNode", () => {
      setHoveredNode(undefined);
    });

    renderer.on("clickNode", ({ node }) => nodeClick(node));

    renderer.setSetting("nodeReducer", (node, data) => {
      const res: Partial<NodeDisplayData> = { ...data };

      if (
        sigmaState.hoveredNeighbors &&
        !sigmaState.hoveredNeighbors.has(node) &&
        sigmaState.hoveredNode !== node
      ) {
        res.label = "";
        res.color = "#f6f6f6";
      }

      if (sigmaState.selectedNode === node) {
        res.highlighted = true;
      } else if (sigmaState.suggestions && !sigmaState.suggestions.has(node)) {
        res.label = "";
        res.color = "#f6f6f6";
      }

      return res;
    });

    renderer.setSetting("edgeReducer", (edge, data) => {
      const res: Partial<EdgeDisplayData> = { ...data };
      if (
        sigmaState.hoveredNode &&
        !graph.hasExtremity(edge, sigmaState.hoveredNode)
      ) {
        res.hidden = true;
      }
      if (
        sigmaState.suggestions &&
        (!sigmaState.suggestions.has(graph.source(edge)) ||
          !sigmaState.suggestions.has(graph.target(edge)))
      ) {
        res.hidden = true;
      }
      return res;
    });
  };

  const renderCanvas = () => {
    const container = document.getElementById("sigma-container") as HTMLElement;
    addNodes();
    addEdges();

    indexParallelEdgesIndex(graph, {
      edgeIndexAttribute: "parallelIndex",
      edgeMinIndexAttribute: "parallelMinIndex",
      edgeMaxIndexAttribute: "parallelMaxIndex",
    });

    actions.setRoutesState({
      nodes: graph
        .nodes()
        .map((node) => {
          let addr = graph.getNodeAttribute(node, "addresses");
          let rou = graph.getNodeAttribute(node, "routes");
          let nodes = [graph.getNodeAttribute(node, "label")];
          if (addr) {
            nodes = [...nodes, ...addr];
          }
          if (rou) {
            nodes = [...nodes, ...rou];
          }
          return nodes;
        })
        .flatMap((i) => i)
        .sort(),
      edges: graph
        .edges()
        .map((edge) => graph.getEdgeAttribute(edge, "label"))
        .sort(),
    });

    circular.assign(graph);
    const settings = forceAtlas2.inferSettings(graph);

    forceAtlas2.assign(graph, { settings, iterations: 600 });
    let tmpRender: Sigma;
    if (renderer) {
      tmpRender = renderer;
      tmpRender.setGraph(graph);
    } else {
      tmpRender = new Sigma(graph, container, {
        nodeProgramClasses: {
          image: NodeImageProgram,
        },
        allowInvalidContainer: true,
        renderEdgeLabels: true,

        edgeLabelColor: { color: "#8ca8cf" },
      });
    }
    setRendererSettings(tmpRender);
    setRenderer(tmpRender);
  };

  const showNoNodeModal = () => {
    Modal.info({
      title: "Info",
      className: "manageUserModal",
      maskClosable: true,
      okText: "Close",
      content: <>No node found</>,
      onOk() {},
      width: 300,
    });
  };

  const checkRoutes = () => {
    if (state.isRoutes) {
      actions.setIsRoutes(!state.isRoutes);
      actions.setIsGateways(false);
    } else {
      actions.setIsRoutes(!state.isRoutes);
    }
  };

  useEffect(() => {
    actions.setIEdges(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isGateways, state.i_edges, state.isRoutes, state.processes]);

  useEffect(() => {
    if (renderer) {
      renderer.clear();
      renderer.removeAllListeners();
      renderer.refresh();
    }

    renderCanvas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.graphData]);

  useEffect(() => {
    renderer && renderer.refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.graphState]);

  return (
    <div>
      <div id="sigma-container"></div>
      <Legend />
      <div id="search">
        <Form form={form}>
          <div
            style={{ color: "var(--header-text-color)", marginBottom: "10px" }}
          >
            <Toggle
              loading={state.isLoading}
              active={state.processes}
              onClick={setData}
              hideReload={true}
              setActive={actions.setIsProcesses}
              title="Show processes"
            ></Toggle>
            <Toggle
              title={"Show interface edges:"}
              loading={state.isLoading}
              hideReload={true}
              active={state.i_edges}
              onClick={setData}
              setActive={actions.setIEdges}
            ></Toggle>
            <Toggle
              title={"Routes:"}
              loading={state.isLoading}
              hideReload={true}
              active={state.isRoutes}
              onClick={checkRoutes}
              setActive={checkRoutes}
            ></Toggle>

            <Toggle
              loading={state.isLoading}
              active={state.isGateways}
              onClick={setData}
              setActive={actions.setIsGateways}
              disable={!state.isRoutes}
              title="Show gateways"
            ></Toggle>
          </div>
          <Form.Item style={{ marginBottom: "10px" }} name="node">
            <Select
              onSearch={(value) => {
                setSearchQuery(value);
              }}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              onBlur={() => {
                setSearchQuery("");
              }}
              showSearch={true}
              placeholder="Try searching for a node..."
              style={{ width: "100%" }}
              id="search-input"
              options={getOptions(state.routes.nodes.filter(onlyUnique))}
            />
          </Form.Item>
          <button
            onClick={() => {
              form.resetFields();
              clearSearch();
            }}
            className="graphClearBtn"
          >
            {" "}
            Clear{" "}
          </button>
        </Form>
      </div>

      <InfoDrawer
        setNodeInfo={actions.setNodeInfo}
        nodeInfo={state.nodeInfo}
        open={state.isDrawerOpen}
        setOpen={actions.setIsDrawerOpen}
      />
    </div>
  );
};

export default NetworkGraph;
