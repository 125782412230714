import {
  Button,
  Checkbox,
  Flex,
  Form,
  FormInstance,
  Input,
  Select,
} from "antd";
import { useEffect } from "react";
import { useBinaryState } from "../../Store";
import {
  ADVANCED_BB_FIELDS,
  BuildModel,
  SearchTargetsModel,
} from "@/shared";
import { getHeaderTargetSelects } from "../../../shared";
import {
  kind_options,
  logging_options,
  parts_options,
  target_arch_options,
  target_os_options,
} from "./Options";
import {
  getTargetInfo,
  showHelpModal,
  showbbParts,
  submit,
} from "../../actions";

type Props = {
  targetsList: SearchTargetsModel[];
  form: FormInstance<any>;
  clear: () => void;
};

const BBForm = ({ targetsList, form, clear }: Props) => {
  const [state, actions] = useBinaryState();
  const initialValues: BuildModel = new BuildModel();

  useEffect(() => {
    form.setFieldValue("parts", state.checkedValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.checkedValues]);

  return (
    <Form
      layout="vertical"
      name="GetFileForm"
      initialValues={initialValues}
      form={form}
      onFinish={(values) => {
        submit(values, targetsList, clear);
      }}
    >
      <Form.Item label="Kind" name="kind">
        <Select
          options={kind_options()}
          onChange={(value) => {
            showbbParts(form, value);
          }}
        />
      </Form.Item>
      {state.isBb && (
        <Form.Item label="parts" name="parts">
          <Select
            mode="multiple"
            options={parts_options()}
            onChange={actions.setChekedValues}
          />
        </Form.Item>
      )}
      <Form.Item name="target_id" label={"Target"}>
        <Select
          showSearch={true}
          options={getHeaderTargetSelects(targetsList).filter(
            (i) => i.label !== "all"
          )}
          onChange={(value) => {
            ADVANCED_BB_FIELDS.includes(state.checkedKind[0]) &&
              getTargetInfo(value, form, targetsList);
          }}
          placeholder="select target"
        />
      </Form.Item>
      <Form.Item label="Operating system" name="target_os">
        <Select
          options={target_os_options}
          placeholder="Select an operating system"
        />
      </Form.Item>
      <Form.Item label="Architecture" name="target_arch">
        <Select
          options={target_arch_options}
          placeholder="Select an architecture"
        />
      </Form.Item>
      <Form.Item label="Tags" name="tags">
        <Input />
      </Form.Item>
      <Form.Item label="Logging" name="logging">
        <Select options={logging_options} placeholder="Select logging option" />
      </Form.Item>
      <p
        style={{
          color: "#4b97af",
          cursor: "pointer",
          marginBlockEnd: state.showAdvanced ? "0.3em" : "1em",
        }}
        onClick={(e) => {
          e.preventDefault();
          actions.setShowAdvanced(!state.showAdvanced);
        }}
      >
        Advanced
      </p>
      <div
        className="hidden"
        style={{ display: state.showAdvanced ? "" : "none" }}
      >
        {ADVANCED_BB_FIELDS.includes(state.checkedKind[0]) && (
          <>
            <Form.Item label="Server IP" name="server_ip">
              <Input style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item label="Domain" name="domain">
              <Input style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item label="Secret" name="secret">
              <Input style={{ width: "100%" }} />
            </Form.Item>
          </>
        )}

        <Form.Item label="Arguments" name="args">
          <Input />
        </Form.Item>
        <Form.Item label="Proxy" name="proxy">
          <Input />
        </Form.Item>

        <Flex>
          <Form.Item
            label="Compress"
            name="compress"
            valuePropName="checked"
            style={{ marginBottom: "30px" }}
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            label="Permanent"
            name="perm_url"
            valuePropName="checked"
            style={{ marginBottom: "30px", marginLeft: "50px" }}
          >
            <Checkbox />
          </Form.Item>
        </Flex>
      </div>
      <div className="form_btns">
        <Button
          disabled={state.isLoading}
          style={{ float: "left" }}
          className="arch"
          onClick={() => showHelpModal(form.getFieldValue("kind"))}
        >
          Help
        </Button>
        <Button
          disabled={state.isLoading}
          style={{ float: "right" }}
          className="arch"
          htmlType="submit"
        >
          Build
        </Button>
      </div>
    </Form>
  );
};

export default BBForm;
