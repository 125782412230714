export class CredentialModel {
  id?: string;
  login: string;
  password: string;
  password_hash: string;
  key: string;
  service: string;
  target: string;
  created_at?: string;
  client: string;
  comment: string;
  source: string;
  cmd: string;
  target_host: "";
  target_host_id: string;
  target_id: string;
  expired_at?: string;
  [key: string]: any;
  constructor() {
    this.login = "";
    this.password = "";
    this.password_hash = "";
    this.key = "";
    this.service = "";
    this.target = "";
    this.client = "";
    this.comment = "";
    this.source = "";
    this.cmd = "";
    this.target_host = "";
    this.target_host_id = "";
    this.target_id = "";
    this.expired_at = "";
  }
}
