import React from "react";
import { useMessagesState } from "../Store";
import { Tooltip } from "antd";
import { SearchTargetsModel } from "@/shared";
import { highlightRow } from "../../actions";

type Props = {
  targetsList: SearchTargetsModel[];
};

export const TopSection = ({ targetsList }: Props) => {
  const [state] = useMessagesState();
  return (
    <div className="top_section">
      <p className="link_target">
        <b>
          Host:{" "}
          <a
            href={`/ui/target-host/${state.beacon?.target_host?.target_host_id}/info`}
          >
            {state.beacon?.target_host?.hostname}
          </a>
        </b>
      </p>
      <p className="link_target">
        <b>
          Target:{" "}
          {state.beacon?.target_host?.target ? (
            <a
              href={`/ui/target/${
                targetsList.find(
                  (i) => i.name === state.beacon?.target_host?.target
                )?.id
              }/info`}
            >
              {state.beacon?.target_host?.target}
            </a>
          ) : (
            "none"
          )}
        </b>
      </p>
      <p className="link_target" style={{ width: "400px" }}>
        <b>
          Next expected emit:{" "}
          {state.beacon?.next_expected_emit
            ? highlightRow(state.beacon)
            : "none"}
        </b>
      </p>
      <Tooltip placement="topLeft" title={state.beacon?.storage_url}>
        <a href={state.beacon?.storage_url} target="_blank" rel="noreferrer">
          Files
        </a>
      </Tooltip>
    </div>
  );
};
