import { ColumnsType } from "antd/es/table";
import {
  SearchTargetsModel,
  customNotIncludesFilter,
  date_sorter,
  defaultFilter,
  getCookie,
  getFilterOptions,
  onlyUnique,
  renderDate,
  render_tags,
  sortOptions, Beacon,
} from "@/shared";
import { host_link, target_link } from "../../NetcatTable/actions";
import {
  BeaconStatus,
  BeaconStatusIcon,
  renderBeaconsIcons,
} from "../../shared";
import { BeaconStore } from "../Store";
import { file_link, highlightRow, last_msg, render_actions } from "../actions";

export const Get_beacon_columns = (
  targetsList?: SearchTargetsModel[],
  colsType?: string,
  setIsRerender?: (isRerender: boolean) => void
) => {
  const state = BeaconStore.storeState.getState();
  const roles = getCookie("roles")?.split(",");

  const columns = (
    [
      {
        title: "Token",
        dataIndex: "token_status",
        key: "Token",
        render: (Status: BeaconStatus, record: Beacon) =>
          record.type === "gecko"
            ? BeaconStatusIcon(Status, record.token_id, setIsRerender, record.token_last_active_at)
            : "",
        width: 70,
        filters: getFilterOptions([
          ...defaultFilter,
          "",
          ...state.tabledata
            ?.map((item: any) => item?.token_status)
            ?.filter(onlyUnique),
        ]),
        filterSearch: true,
        onFilter: (value: any, record: any) =>
          customNotIncludesFilter(
            record?.token_status,
            value,
            BeaconStore.storeState.getState().filteredInfo?.Token
          ),
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        width: 55,
        render: (value: string) => renderBeaconsIcons([value]),
        filters: getFilterOptions([
          ...defaultFilter,
          ...state.tabledata
            ?.map((item: any) => item.type)
            ?.filter(onlyUnique)
            .sort(),
        ]),
        filterSearch: true,
        onFilter: (value: any, record: any) =>
          customNotIncludesFilter(
            record?.type,
            value,
            BeaconStore.storeState.getState().filteredInfo?.type
          ),
      },
      {
        title: "Version",
        dataIndex: "version",
        key: "version",
        width: 70,
        filters: getFilterOptions([
          ...defaultFilter,
          "",
          ...state.tabledata
            ?.map((item: any) => item?.version)
            ?.filter(onlyUnique)
            .sort(),
        ]),
        filterSearch: true,
        onFilter: (value: any, record: any) =>
          customNotIncludesFilter(
            record?.version,
            value,
            BeaconStore.storeState.getState().filteredInfo?.version
          ),
      },
      {
        title: "Rpc address",
        dataIndex: "rpc_address",
        key: "rpc_address",
        width: 110,
        filters: getFilterOptions([
          ...defaultFilter,
          "",
          ...state.tabledata
            ?.map((item: any) => item?.rpc_address)
            ?.filter(onlyUnique)
            .sort(),
        ]),
        filterSearch: true,
        onFilter: (value: any, record: any) => {
          return customNotIncludesFilter(
            record?.rpc_address,
            value,
            BeaconStore.storeState.getState().filteredInfo?.rpc_address
          );
        },
      },
      {
        title: "GW",
        dataIndex: "gateway_address",
        key: "gateway_address",
        width: 110,
        filters: getFilterOptions([
          ...defaultFilter,
          "",
          ...state.tabledata
            ?.map((item: any) => item?.gateway_address)
            ?.filter(onlyUnique)
            .sort(),
        ]),
        filterSearch: true,
        onFilter: (value: any, record: any) => {
          return customNotIncludesFilter(
            record?.gateway_address,
            value,
            BeaconStore.storeState.getState().filteredInfo?.gateway_address
          );
        },
      },
      {
        title: "Transport",
        dataIndex: "transport",
        key: "transport",
        width: 90,
        filters: getFilterOptions([
          ...defaultFilter,
          "",
          ...state.tabledata
            ?.map((item: any) => item?.transport)
            ?.filter(onlyUnique)
            .sort(),
        ]),
        filterSearch: true,
        onFilter: (value: any, record: any) =>
          customNotIncludesFilter(
            record?.transport,
            value,
            BeaconStore.storeState.getState().filteredInfo?.transport
          ),
      },
      roles?.includes("all-beacons")
        ? {
            title: "Client",
            dataIndex: "target_host",
            key: "client",
            render: (value: any, record: any) => (
              <>{record.target_host.client}</>
            ),
            width: 70,
          }
        : null,
      colsType === undefined ||
      colsType === "targetInfo" ||
      colsType === "binaryInfo"
        ? {
            title: "Host name",
            dataIndex: "target_host",
            key: "hostname",
            filters: sortOptions(
              getFilterOptions(
                state.tabledata
                  ?.map((x) => x.target_host.hostname)
                  ?.filter(onlyUnique)
              )
            ),
            filterSearch: true,
            onFilter: (value: any, record: any) =>
              record.hostname?.includes(value),
            render: (value: any, record: any) =>
              host_link(value.hostname, record.target_host.target_host_id),
          }
        : null,
      colsType === undefined ||
      colsType === "hostInfo" ||
      colsType === "binaryInfo"
        ? {
            title: "Target",
            dataIndex: "target_host",
            key: "target",
            render: (value: any, record: any) =>
              target_link(value.target, record.target_host, targetsList),
          }
        : null,
      colsType === undefined ||
      colsType === "targetInfo" ||
      colsType === "binaryInfo"
        ? {
            title: "Client IP",
            dataIndex: "client_ip",
            key: "client_ip",
          }
        : null,
      {
        title: "Tags",
        dataIndex: "tags",
        key: "tags",
        filters: [
          {
            text: "not includes",
            value: "not includes",
          },
          {
            text: "",
            value: "",
          },
          ...sortOptions(
            getFilterOptions(
              state.tabledata?.flatMap((x) => x.tags)?.filter(onlyUnique)
            )
          ),
        ],
        width: 70,
        filterSearch: true,
        render: (value: string[]) => render_tags(value),
        onFilter: (value: any, record: any) =>
          customNotIncludesFilter(
            record?.tags,
            value,
            BeaconStore.storeState.getState().filteredInfo?.tags
          ),
      },
      {
        title: "Created",
        dataIndex: "created_at",
        key: "created_at",
        render: renderDate,
        sorter: (a: any, b: any) => date_sorter(a.created_at, b.created_at),
      },
      {
        title: "Updated",
        dataIndex: "updated_at",
        key: "updated_at",
        render: renderDate,
        sorter: (a: any, b: any) => date_sorter(a.updated_at, b.updated_at),
      },
      {
        title: "Last emitted",
        dataIndex: "last_emitted_at",
        key: "last_emitted_at",
        render: renderDate,
        sorter: (a: any, b: any) =>
          date_sorter(a.last_emitted_at, b.last_emitted_at),
      },
      {
        title: "Next expected emit",
        dataIndex: "next_expected_emit",
        key: "next_expected_emit",
        render: (text: any, record: any) => highlightRow(record),
        sorter: (a: any, b: any) =>
          date_sorter(a.next_expected_emit, b.next_expected_emit),
      },
      {
        title: "Last message processed",
        dataIndex: "last_message_status",
        key: "last_message_status",
        render: last_msg,
      },

      {
        title: "Files",
        dataIndex: "storage_url",
        key: "storage_url",
        ellipsis: {
          showTitle: false,
        },
        render: file_link,
      },
      {
        title: "Comment",
        dataIndex: "comment",
        key: "comment",
        width: 250,
      },
      {
        title: "Actions",
        dataIndex: "Actions",
        key: "Actions",
        render: (_text: any, record: any) =>
          render_actions(record, setIsRerender),
        width: 170,
        fixed: "right",
      },
    ] as ColumnsType<any>
  ).filter((i) => i !== null);

  return {
    columns: columns,
    column_names: columns.map((item) => item.title?.toString()) as string[],
  };
};
