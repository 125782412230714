import { AxiosError } from "axios";
import { handleError } from "@/shared";
import  { Api } from ".";

export class UserTokensApi {
  getTokens = async () => {
    try {
      const response = await Api.get("/api/v1/token/list")
        .then((response: { data: any }) => {
          return response.data;
        });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  }

  deleteTokenByID = async (id: any) => {
    try {
      const response = await Api.remove(`/api/v1/token/${id}`)
        .then((response: { data: any }) => {
          return response.data;
        });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  }

  getTokensUsage = async (id: string) => {
    try {
      const response = await Api.get(`/api/v1/token/${id}/usages`)
        .then((response: { data: any }) => {
          return response.data;
        });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  }

  switchToken = async (id: string) => {
    try {
      const response = await Api.put(`/api/v1/token/${id}/switch`)
        .then((response: { data: any }) => {
          return response.data;
        });
      return response;

    } catch (error) {
      handleError(error as AxiosError);
    }
  }
}
