import axios, {AxiosError} from "axios";
import "dotenv/config";
import {getCookie, handleError} from "@/shared";
interface HttpResponse {
  headers: any;
  data: any;
}

const CURRENT_URL =
  process.env.REACT_APP_BASE_URL !== undefined
    ? process.env.REACT_APP_BASE_URL
    : "";

axios.defaults.maxRedirects = 1;
axios.interceptors.request.use(
  (config) => {
    const token = getCookie("token") as string;
    const client_id = getCookie("ClientId") as string;

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    if (client_id) {
      config.headers["Client-ID"] = client_id;
    }

    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res: any) => {
    if (res && [301, 302].includes(res.status)) {
      const redirectUrl = res.headers.location;
      window.location.href = redirectUrl;
    }
    return res;
  },
  (err: { response: any }) => {
    return Promise.reject(err);
  }
);

const get = async (
  url: string,
  data?: any,
  paramsSerializer?: any
): Promise<HttpResponse> => {
  const response = await axios.get(CURRENT_URL + url, {
    params: data,
    maxRedirects: 1,
    paramsSerializer: paramsSerializer,
    headers: {
      "Access-Control-Allow-Headers": "*",
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
    },
  });
  return response;
};

const getFile = async (
  url: string,
  data?: any,
  paramsSerializer?: any
): Promise<HttpResponse> => {
  const response = await axios.get(CURRENT_URL + url, {
    responseType: "blob",
    params: data,
    paramsSerializer: paramsSerializer,
  });
  return response;
};

const postFile = async (
  url: string,
  data?: any,
  paramsSerializer?: any
): Promise<HttpResponse> => {
  const response = await axios.post(CURRENT_URL + url, data, {
    responseType: "blob",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Expose-Headers":
        "Content-Disposition,X-Suggested-Filename",
    },
    paramsSerializer: paramsSerializer,
  });
  return response;
};

const uploadFile = async (
  url: string,
  data?: FormData,
  paramsSerializer?: any
): Promise<HttpResponse> => {
  const response = await axios.post(CURRENT_URL + url, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
    paramsSerializer: paramsSerializer,
  });
  return response;
};

const post = async (url: string, data?: any): Promise<HttpResponse> => {
  const response = await axios.post(CURRENT_URL + url, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type",
    },
  });
  return response;
};

const put = async (url: string, data?: any): Promise<HttpResponse> => {
  const response = await axios.put(CURRENT_URL + url, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return response;
};

const patch = async (url: string, data?: any): Promise<HttpResponse> => {
  const response = await axios.patch(CURRENT_URL + url, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return response;
};

const remove = async (
  url: string,
  data?: any,
  options: any = {}
): Promise<HttpResponse> => {
  const response = await axios.delete(CURRENT_URL + url, {
    ...options,
    params: data,
  });
  return response;
};

const getEnvInfo = async () => {
  try {
    const response = await get("/api/v1/env-info");
    return response.data.environment;
  } catch (error) {
    handleError(error as AxiosError);
  }
}

export { get, getFile, post, put, patch, remove, postFile, uploadFile, getEnvInfo };
