import React, { createContext, useState } from "react";
import { SearchApi } from "@/Api";
import { SearchTargetsModel } from "@/shared";

export const TargetContext = createContext<{
  targetsList: SearchTargetsModel[];
  initTargets: () => Promise<void>;
} | null>(null);

const SEARCH_CLIENT = new SearchApi();

const TargetProvider = ({ children }: React.PropsWithChildren) => {
  const [targetsList, setTargetsList] = useState<SearchTargetsModel[]>([]);
  const initTargets = async () => {
    setTargetsList(await SEARCH_CLIENT.getData());
  };

  return (
    <TargetContext.Provider value={{ targetsList, initTargets }}>
      {children}
    </TargetContext.Provider>
  );
};

export default TargetProvider;
