import { AxiosError } from "axios";
import { handleError } from "@/shared";
import { Api } from ".";

export class TokenApi {
  getTokensList = async () => {
    try {
      const response = await Api.get("/api/v1/server-token/list")
        .then((response: { data: any }) => {
          return response.data;
        });
      return response;

    } catch (error) {
      handleError(error as AxiosError);
    }
  }

  deleteTokenById = async (params: string) => {
    try {
      const response = await Api.remove(`/api/v1/server-token/${params}`)
        .then((response: { data: any }) => {
          return response.data;
        });
      return response;

    } catch (error) {
      handleError(error as AxiosError);
    }
  }

  addNewToken = async (params: string) => {
    try {
      const response = await Api.post(`/api/v1/server-token`, { server: params })
        .then((response: { data: any }) => {
          return response.data;
        });
      return response;

    } catch (error) {
      handleError(error as AxiosError);
    }

  }
}
