import React from 'react'
import {TargetNote, renderHostLinks} from '@/shared'
import { List, Tooltip } from 'antd'
import cls from '../notes.module.css';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { TargetApi } from "@/Api";

const TARGET_CLIENT = new TargetApi();

type Props = {
    id: string | undefined,
    notes: TargetNote[],
    deleteNote: (item: TargetNote) => void
}

const NodesList: React.FC<Props> = ({ id, notes, deleteNote }) => {
    return (
        <List className={cls.nodesList} itemLayout="horizontal" dataSource={notes}
            renderItem={(i, index) => (
                <List.Item>
                    <List.Item.Meta title={<>
                        <a href={`/ui/target/${id}/note/${i.id}`} 
                            className={cls.titleLink}> {i.title + ' >'} </a> 
                        <Tooltip title="Delete note" color='red'>
                            <DeleteOutlined
                                onClick={() => { deleteNote(i) }} 
                                className={`${cls.actionBtn} ${cls.deleteBtn}`} />
                        </Tooltip>

                        <Tooltip title="Export note as .txt" color='darkblue'>
                            <DownloadOutlined className={`${cls.actionBtn} ${cls.downloadBtn}`}
                                onClick={() => TARGET_CLIENT.downloadNoteFile(id!, i)}
                            />
                        </Tooltip>

                        </>} description={<>
                            <><b>Last updated by:</b> {i.user}</>/
                            <><b> Version:</b> {i.version} </>/
                            <><b> Created:</b> 
                            { moment(i.created_at).format('MMM DD YYYY, HH:mm') } </>/
                            <><b> Updated:</b> 
                            { moment(i.updated_at).format('MMM DD YYYY, HH:mm') } </>
                            <br />
                            <><b>Hosts:</b>&nbsp;
                            { i.hosts.length > 0 ? 
                                renderHostLinks(i.hosts) : 'There are no hosts' } </> 
                            <br />
                            
                            { i.file_url &&  (
                                <><b> Content file:</b>&nbsp;
                                <a href={i.file_url} target="_blank" rel="noopener noreferrer">
                                    { i.file_name }
                                </a>
                                </>
                            )}
                        </>} 
                    />
                </List.Item>
            )}
        />
    )
}

export default NodesList
