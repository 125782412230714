import { Modal } from "antd";
import {
  HostInfo,
  SearchTargetsModel,
  collection_result_analysis,
  generateConnectionString,
  process,
} from "@/shared";
import { HostInfoStore } from "../store";
import { TargetApi } from "@/Api";

const TARGET_CLIENT = new TargetApi();

export const handle_data = (hostDetails: HostInfo) => {
  if (hostDetails) {
    hostDetails.remote_sessions = hostDetails?.remote_sessions.map((x: any) => {
      x.client = x.client ? x.client : "";
      x.connection_string = generateConnectionString(x);
      return x;
    });
    hostDetails.collection_result_analysis = analysisMap(
      hostDetails.collection_result_analysis
    );
    return hostDetails;
  } else {
    return new HostInfo();
  }
};

export const analysisMap = (data: any) => {
  let analysis: collection_result_analysis[] = [];
  data?.forEach((item: any) => {
    let processes: process[] = [];
    Object.keys(item.processes).forEach((proc, index) => {
      let process: process = item.processes[proc];
      process.process = proc;
      process.id = index;
      processes.push(process);
    });
    analysis.push({
      collection_result_id: item.collection_result_id,
      processes: processes,
      miscellaneous_files: item.ssh.miscellaneous_files,
      keys: item.ssh.keys,
    });
  });
  return analysis;
};

export const setStartKeys = (data: any) => {
  let keys = [];
  if (data?.remote_sessions.length > 0) {
    keys.push("100");
  }
  if (data?.beacons?.length > 0) {
    keys.push("101");
  }
  if (data?.collection_results?.length > 0) {
    keys.push("102");
  }
  if (data?.patched_bins?.length > 0) {
    keys.push("103");
  }
  if (data?.connection_paths?.length > 0) {
    keys.push("104");
  }
  if (data?.credentials?.length > 0) {
    keys.push("105");
  }
  HostInfoStore.actions.setKeys(keys);
};

export const removeConnection = async () => {
  let state = HostInfoStore.storeState.getState();
  Modal.confirm({
    title: `Do you want to delete this item?  `,
    content: (
      <>
        Host name: {state.hostDetails?.target_host?.hostname} <br></br> External
        IP: {state.hostDetails.target_host?.external_ip}
      </>
    ),
    okText: "delete",
    okType: "danger",
    onOk: async () => {
      await TARGET_CLIENT.removeById(state.hostDetails.target_host?.id);
      window.history.back();
    },
  });
};

export const update_host = async (
  value: string,
  targetsList: SearchTargetsModel[],
  id: string,
  silent?: boolean
) => {
  let state = HostInfoStore.storeState.getState();
  await TARGET_CLIENT.updateHost({
    id: state.hostDetails.target_host?.id,
    target_id: targetsList?.find((i) => i.name === value)?.id,
    commentText: state.hostDetails.target_host?.comment,
    silent,
  });
  HostInfoStore.actions.setHostInfo(id, state.reRender);
};
