import { AxiosError } from "axios";
import { handleError } from "@/shared";
import { Api } from ".";

export class TableDataApi {
  getBinTAbleData = async () => {
    try {
      const response = await Api.get("/api/binary/list").then(
        (response: any) => {
          return response.data;
        }
      );
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  getBBOptions = async () => {
    try {
      const response = await Api.get("/api/v1/binary/parts").then(
        (response: any) => {
          return response.data;
        }
      );
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  getBinaryHelp = async (kind: any) => {
    try {
      const response = await Api.get(`/api/binary/help/${kind}`).then(
        (response: any) => {
          return response.data;
        }
      );
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  getData = async (data: any) => {
    try {
      const response = await Api.post(`/api/collection-result/list`, data).then(
        (response: any) => {
          return response.data;
        }
      );
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  buildBinaryFile = async (params: any) => {
    try {
      const response = await Api.post(`/api/binary/build`, params).then(
        (response: any) => {
          return response.data;
        }
      );
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  loadFile = async (params: any) => {
    try {
      let url = `/api/collection-result/${params.id}`;
      if (params.isMd) {
        url += `?md=true`;
      }
      const response = await Api.getFile(url).then((response) => {
        const href = window.URL.createObjectURL(response.data);
        const anchorElement = document.createElement("a");
        anchorElement.href = href;
        if (params.isMd) {
          anchorElement.download = `${params.fileName}.md`;
        } else {
          anchorElement.download = params.fileName;
        }
        document.body.appendChild(anchorElement);
        anchorElement.click();
        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
      });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  removeFileById = async (params: any) => {
    try {
      const response = await Api.remove(
        `/api/collection-result/${params}`
      ).then((response) => {
        return response.data;
      });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  removeBinaryFileById = async (params: any) => {
    try {
      const response = await Api.remove(`/api/binary/${params}`).then(
        (response) => {
          return response.data;
        }
      );
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  loadSelectedFiles = async (params: any) => {
    try {
      let url = `/api/collection-result/bulk`;
      const response = await Api.postFile(url, params).then((response) => {
        const href = window.URL.createObjectURL(response.data);
        const anchorElement = document.createElement("a");
        anchorElement.href = href;
        anchorElement.download = response.headers["content-disposition"]
          .split(";")[1]
          .trim()
          .split("=")[1]
          .replace(/"/g, "");
        document.body.appendChild(anchorElement);
        anchorElement.click();
        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
      });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  uploadFile = async (file: any) => {
    try {
      const response = await Api.uploadFile(
        "/api/collection-result/upload",
        file
      ).then((response) => {
        return response;
      });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  };
}
