import { ColumnsType } from "antd/es/table";
import { SearchTargetsModel, getFilterOptions, onlyUnique, renderDate, sortOptions } from "@/shared";
import { renderInstall, renderResult, render_actions } from "../actions";


export const bynary_columns = (
    tabledata: any[],
    targetsList: SearchTargetsModel[]
) => [
    {
        title: "Kind",
        dataIndex: "bin",
        key: "bin",
        width: 100,
    },
    {
        title: "OS",
        dataIndex: "target_os",
        key: "target_os",
        width: 70,
    },
    {
        title: "Arch",
        dataIndex: "target_arch",
        key: "target_arch",
        width: 90,
    },
    {
        title: "Version",
        dataIndex: "version",
        key: "version",
    },
    {
        title: "Logging",
        dataIndex: "logging",
        key: "logging",
    },
    {
        title: "Tags",
        dataIndex: "tags",
        key: "tags",
        filters: sortOptions(getFilterOptions(tabledata ? tabledata.flatMap(item => item.tags).filter(onlyUnique) : [])),
        filterMultiple: true,
        filterSearch: true,
        onFilter: (value: any, record: any) => record.tags?.includes(value),
        render: (value: any, record: any) => record.tags?.toString().replaceAll(',', ', ')
    },
    {
        title: "Parts",
        dataIndex: "parts",
        key: "parts",
        filters: sortOptions(getFilterOptions(tabledata ? tabledata.flatMap(item => item.parts).filter(onlyUnique) : [])),
        filterMultiple: true,
        filterSearch: true,
        onFilter: (value: any, record: any) => record.parts.includes(value),
        render: (value: any, record: any) => record.parts?.toString().replaceAll(',', ', ')
    },
    {
        title: "Proxy",
        dataIndex: "proxy",
        key: "proxy",
    },
    {
        title: "Domain",
        dataIndex: "domain",
        key: "domain",
    },
    {
        title: "Server ip",
        dataIndex: "server_ip",
        key: "server_ip",
    },
    {
        title: "Target",
        dataIndex: "target",
        key: "target",
        width: 150,
        render: (value: any, record: any) => {
            let id = targetsList?.find(i => i.name === record.target)?.id;
            return id ? <a href={`/ui/target/${id}/info`} >{value ? value : '{target}'}</a> : value ? <>{value}</> : <></>
        },
        sorter: (a: { target: string; }, b: { target: string; }) => a.target?.toLowerCase() > b.target?.toLowerCase() ? 1
            : ((b.target?.toLowerCase() > a.target?.toLowerCase()) ? -1 : 0)
    },
    {
        title: "Install",
        dataIndex: "install",
        key: "install",
        width: 120,
        ellipsis: {
            showTitle: false,
        },
        render: (value: any, record: any) => renderInstall(record),
    },
    {
        title: "Result",
        dataIndex: "result",
        key: "result",
        width: 250,
        render: (_text: any, record: any) => renderResult(record)
    },
    {
        title: "Created",
        dataIndex: "created_at",
        key: "created_at",
        width: 135,
        render: (created_at: any) => renderDate(created_at),
    },
    {
        title: "Updated",
        dataIndex: "updated_at",
        key: "updated_at",
        width: 135,
        render: (updated_at: any) => renderDate(updated_at),
    },
    {
        title: "",
        dataIndex: "Actions",
        key: "Actions",
        render: (_text: any, record: any) => render_actions(record),
        width: 50,
        fixed: 'right',
    }
] as ColumnsType<any>


