import { AxiosError } from "axios";
import { Api } from ".";
import { handleError } from "@/shared";

export class StatApi {
    getStats = async (params: any) => {
        try {
            const response = await Api.get("/api/v1/target/updates", params)
                .then((response: { data: any }) => {
                    return response.data;
                });
            return response;

        } catch (error) {
            handleError(error as AxiosError);
        }
    }
}
