import { AxiosError } from "axios";
import { handleError } from "@/shared";
import { Api } from ".";

export class ClientsApi {
  getData = async () => {
    try {
      const response = await Api.get("/api/client/list")
        .then((response: { data: any }) => {
          return response.data;
        });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  }

  addNewClient = async (data: any) => {
    try {
      const response = await Api.post("/api/client", data)
        .then((response: any) => {
          return response
        });

      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  }

  updateClient = async (params: any) => {
    try {
      const response = await Api.put(`/api/client/${params.id}`, params)
        .then((response) => {
          return response.data;
        })
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  }

  getClientById = async (params: any) => {
    try {
      const response = await Api.get(`/api/client/${params}`)
        .then((response) => {
          return response.data;
        });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  }

  removeClientById = async (params: any) => {
    try {
      const response = await Api.remove(`/api/client/${params}`)
        .then((response) => {
          return response.data;
        });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  }

  getUsers = async () => {
    try {
      const response = await Api.get("/api/user/list")
        .then((response: { data: any }) => {
          return response.data;
        });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  }

  getUserById = async (params: any) => {
    try {
      const response = await Api.get(`/api/user/${params}`)
        .then((response: { data: any }) => {
          return response.data;
        });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  }


  getUsersByClient = async (params: any) => {
    try {
      const response = await Api.get(`/api/client/${params}/user/list`)
        .then((response: { data: any }) => {
          return response.data;
        });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  }

  updateUser = async (params: any) => {
    try {
      const response = await Api.put(`/api/user/${params.id}`, params)
        .then((response) => {
          return response.data;
        });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  }

  addNewUser = async (data: any) => {
    try {
      const response = await Api.post("/api/user", data)
        .then((response: any) => {
          return response
        });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  }

  removeUserById = async (params: any) => {
    try {
      const response = await Api.remove(`/api/user/${params}`)
        .then((response) => {
          return response.data;
        });
      return response;
    } catch (error) {
      handleError(error as AxiosError);
    }
  }

}
