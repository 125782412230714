import React, { useEffect, useState } from "react";
import { StatApi } from "@/Api";
import cls from "./stat.module.css";
import { useForm } from "antd/es/form/Form";
import DateForm from "./ui/DateForm";
import BeaconTable from "../Beacon/BeaconTable";
import { Collapse, CollapseProps } from "antd";
import NetcatTablePage from "../NetcatTable/NetcatTablePage";

type Props = {};

const STAT_CLIENT = new StatApi();

const Statistics = (props: Props) => {
  const [data, setData] = useState<any[]>([]);
  const [key, setKey] = useState<string | string[]>([]);
  const [rerender, setRerender] = useState(false);
  const [form] = useForm();

  const loadData = () => {
    form.validateFields().then(
      async (result) => {
        let resp = await STAT_CLIENT.getStats({
          since: new Date(result.since?.format()).toISOString(),
          until: new Date(result.until?.format()).toISOString(),
        });

        resp?.entries.sort((a: any, b: any) => {
          return a.target_name?.localeCompare(b.target_name);
        });
        setData(resp?.entries);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const generateLabel = (item: any, index: number) => (
    <>
      {(item.target_name ? item.target_name : `no name ${index}`) + ":"}
      <span className={cls.title_info}>
        <br />{" "}
        {` lost beacons - ${item.lost_beacons?.length}, new beacons - ${item.new_beacons?.length}, ` +
          `lost sessions: - ${item.lost_sessions?.length}, new sessions: - ${item.new_sessions?.length}`}
      </span>
    </>
  );

  const createCollapse = () => {
    if (data?.length > 0) {
      let items: CollapseProps["items"] = data?.map((item, index) => {
        return {
          key: index,
          label: generateLabel(item, index),
          children: (
            <>
              {item.lost_beacons?.length > 0 && (
                <>
                  <span className={cls.table_lable}>
                    <label htmlFor="">Lost beacons</label>
                  </span>
                  <BeaconTable
                    beacons={item.lost_beacons}
                    setIsRerender={setRerender}
                    colsType="targetInfo"
                  />
                </>
              )}
              {item.new_beacons?.length > 0 && (
                <>
                  <span className={cls.table_lable}>
                    {" "}
                    <label htmlFor="">New beacons</label>
                  </span>
                  <BeaconTable
                    beacons={item.new_beacons}
                    setIsRerender={setRerender}
                    colsType="targetInfo"
                  />
                </>
              )}
              {item.lost_sessions?.length > 0 && (
                <>
                  <span className={cls.table_lable}>
                    <label className={cls.table_lable} htmlFor="">
                      Lost sessions
                    </label>
                  </span>
                  <NetcatTablePage
                    sessions={item.lost_sessions}
                    setIsRerender={setRerender}
                    colsType="targetInfo"
                  />
                </>
              )}
              {item.new_sessions.length > 0 && (
                <>
                  {" "}
                  <span className={cls.table_lable}>
                    <label className={cls.table_lable} htmlFor="">
                      New sessions
                    </label>
                  </span>
                  <NetcatTablePage
                    sessions={item.new_sessions}
                    setIsRerender={setRerender}
                    colsType="targetInfo"
                  />
                </>
              )}
            </>
          ),
        };
      });

      return (
        <Collapse
          className="collapse stats_collapse"
          style={{ marginTop: "5px" }}
          items={items}
          activeKey={key}
          onChange={(key) => {
            setKey(key);
          }}
        />
      );
    } else {
      return;
    }
  };

  useEffect(() => {
    rerender && loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rerender]);

  useEffect(() => {
    document.title = "Satistics / BartUI ";
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="messages_table">
      <DateForm loadData={loadData} form={form} />
      {createCollapse()}
    </div>
  );
};

export default Statistics;
