import { BeaconApi } from "@/Api";
import {
  BeaconMessage,
  actionsType, onlyUnique,
} from "@/shared";
import { getSessions } from "../actions";
import { Sessions } from "@/Components/Beacon/MessagesTable/ui";
import { IMessagesState } from "./IMessagesState";

const BEACON_CLIENT = new BeaconApi();

export const actions = {
  setCommands:
    (commands: string[]) =>
    ({ setState, getState }: actionsType<IMessagesState>) =>
      setState({ ...getState(), commands }),

  setMessages:
    (messages: BeaconMessage[]) =>
    ({ setState, getState }: actionsType<IMessagesState>) =>
      setState({ ...getState(), messages }),

  setMsgsInProgress:
    (msgsInProgress: BeaconMessage[]) =>
    ({ setState, getState }: actionsType<IMessagesState>) =>
      setState({ ...getState(), msgsInProgress }),

  setSessions:
    (sessions: Sessions) =>
    ({ setState, getState }: actionsType<IMessagesState>) =>
      setState({ ...getState(), sessions }),

  updateAutocomplete: (existingOptions?: string[]) =>
    async ({ setState, getState }: actionsType<IMessagesState>) => {
      let optionsList: string[] = existingOptions ? existingOptions : [];
      let messages = getState().messages;
      if (messages) {
          messages.map((msg: BeaconMessage, i: number, messages: BeaconMessage[]) => {
              msg = { ...msg, count: i + 1 };
              const { request } = msg;
              optionsList.push(request);
              return msg;
          });
      }
      let options = optionsList.filter(onlyUnique).map(option => ({ value: option }));
      setState({ ...getState(), options});
  },

  setBeacon:
    (id: string) =>
      async ({ setState, getState, dispatch }: any) => {
        let beacon = await BEACON_CLIENT.getBeaconById(`${id}`);
        setState({ ...getState(), beacon});

        dispatch(actions.fetchMessages(true));
        dispatch(actions.updateAutocomplete(getState().options.map((i: any) => i.value)));

        let msgsInProgress = getState().messages?.filter((i: any) => !i.processed_at);
        setState({ ...getState(), msgsInProgress });

        getSessions(beacon?.target_host?.target_host_id);
      },

  fetchMessages: (reset: boolean = false) => async ({setState, getState}: actionsType<IMessagesState>) => {
    if (reset) {
      setState({...getState(), hasMore: true, page: 1});
    }
    if (!getState().hasMore) return;

    setState({...getState(), loading: true});
    try {
      const offset = (getState().page - 1) * getState().pageSize;
      let messages = await BEACON_CLIENT.getMessages(getState().beacon?.id!, getState().pageSize, offset);

      if (messages.length < getState().pageSize) {
        setState({...getState(), hasMore: false});
      }
      if (messages.length > getState().pageSize) {
        console.error("Panic, more messages than requested. Messages: ", messages.length, "PageSize", getState().pageSize);
        setState({...getState(), hasMore: false});
      }

      if (reset) {
        setState({...getState(), messages: messages});
      } else {
        setState({...getState(), messages: [...getState().messages, ...messages]});
      }
      setState({...getState(), page: getState().page + 1});

    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setState({...getState(), loading: false});
    }
  },
};
