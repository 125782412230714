import { Tooltip } from "antd";
import {
  CheckOutlined,
  DeleteOutlined, DislikeOutlined,
  EditOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import { SearchTargetsModel, copyToClipboard } from "@/shared";
import moment from "moment";
import { editCreds, removeCreds, setEditedModel } from ".";
import { NavigateFunction } from "react-router-dom";
import React from "react";

export const editfavorite = (record: any, fn: () => void) => {
  editCreds({ ...record, favorite: !record.favorite }, fn);
};

export const editLastUsed = (record: any, fn: () => void) => {
  editCreds({ ...record, last_used: new Date().toISOString() }, fn);
};

export const editExpiredAt = (record: any, fn: () => void) => {
  editCreds({ ...record, expired_at: new Date().toISOString() }, fn);
};

export const renderAction = (record: any, fn: () => void) => {
  return (
    <div className="action-btns">
      <Tooltip title="Set last used" color="green">
        <CheckOutlined
          onClick={(e) => {
            e.preventDefault();
            editLastUsed(record, fn);
          }}
        />
      </Tooltip>
      <Tooltip title="Set expired">
        <DislikeOutlined
          className="set-expired-icon"
          onClick={(e) => {
            e.preventDefault();
            editExpiredAt(record, fn);
          }}
        />
      </Tooltip>
      <span
        title="Mark as favourite"
        style={{ color: "#F4A460" }}
        onClick={(e) => {
          editfavorite(record, fn);
        }}
      >
        {record.favorite ? <StarFilled /> : <StarOutlined />}
      </span>
      <EditOutlined
        title="Edit"
        onClick={() => {
          setEditedModel(record);
        }}
        style={{ color: "green" }}
      />
      <DeleteOutlined
        style={{ color: "red" }}
        onClick={() => {
          removeCreds(record.id, fn);
        }}
      />
    </div>
  );
};

export const renderCopiedData = (
  id: string,
  data: any,
  y: number,
  x?: number
) => {
  return (
    <span
      id={id + "id"}
      onDoubleClick={(event) => {
        copyToClipboard(
          id + "id",
          data,
          "cpdpass",
          event.clientY + y,
          x ? `${event.clientX + x}px` : undefined
        );
      }}
    >
      {data}
    </span>
  );
};

export const renderDate = (date: any) =>
  moment(date).format("MMM DD YYYY, HH:mm").toString();

export const render_file_link = (_text: any, record: any) => {
  let file = new Blob([_text], { type: "text/plain" });
  return (
    <a
      download={`${record.id}`}
      target="_blank"
      rel="noreferrer"
      href={URL.createObjectURL(file)}
    >
      {record.key}
    </a>
  );
};

export const render_target_link = (
  value: any,
  record: any,
  targetsList: SearchTargetsModel[],
  navi: NavigateFunction
) => {
  let id = targetsList?.find((i) => i.name === record.target)?.id;
  return id ? (
    <a
      href={`/ui/target/${id}/info`}
      style={{
        color: "rgb(24 132 239)",
        textDecoration: "underline",
        cursor: "pointer",
      }}
      onClick={(e) => {
        if (!e.ctrlKey) {
          e.preventDefault();
          navi(`/ui/target/${id}/info`);
        }
      }}
    >
      {value ? value : "{target}"}
    </a>
  ) : value ? (
    <>{value}</>
  ) : (
    <></>
  );
};

export const render_host_link = (
  value: any,
  record: any,
  navi: NavigateFunction
) => {
  return (
    <a
      href={`/ui/target-host/${record.target_host_id}/info`}
      style={{
        color: "rgb(24 132 239)",
        textDecoration: "underline",
        cursor: "pointer",
      }}
      onClick={(e) => {
        if (!e.ctrlKey) {
          e.preventDefault();
          navi(`/ui/target-host/${record.target_host_id}/info`);
        }
      }}
    >
      {value}
    </a>
  );
};
