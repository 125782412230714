import { TargetApi } from "@/Api";
import { collection_result_analysis, process } from "@/shared";
import { TargetStore } from "../Store";

const TARGET_CLIENT = new TargetApi();

export const getTargetDetails = async (
  id: string | undefined,
  isRerender?: boolean
) => {
  TargetStore.actions.setLoading(true);
  let response = await TARGET_CLIENT.getTargetData(`${id}`);
  TargetStore.actions.setTargetDetails(response.target);
  TargetStore.actions.setLoading(false);
};

export const _analysisMap = (data: any) => {
  return data?.map((item: any) => {
    return {
      collection_result_id: item.collection_result_id,
      processes: _get_processes(item),
      miscellaneous_files: item.ssh.miscellaneous_files,
      keys: item.ssh.keys,
    };
  }) as collection_result_analysis[];
};

const _get_processes = (item: any) => {
  let processes: process[] = [];
  Object.keys(item.processes).forEach((proc, index) => {
    processes.push({ ...item.processes[proc], process: proc, id: index });
  });
  return processes;
};
