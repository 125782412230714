import {TargetApi} from "@/Api";
import {createHook, createStore} from 'react-sweet-state';
import {validateIPAddress} from "@/shared/extentions/validators";

const TARGET_CLIENT = new TargetApi();

export enum ParserState {
  Init = "init",
  ParseResult = "parse_result",
  SaveResult = "save_result",
}

interface SaveResult {
  host_id: string;
  hostname: string;
  note_id: string;
  note_updated: boolean;
  note_title: string;
  host_updated: boolean;
}

interface FoundHost {
  machine_id: string;
  hostname: string;
  host_id: string;
}

interface HostInterface {
  name: string;
  ip_address: string;
  netmask: string;
  network: string;
  comment: string;
  enabled: boolean;
  errors: string[];
  parsing_notes: string[];
}

interface HostRoute {
  interface: string;
  destination: string;
  netmask: string;
  gateway: string;
  routing_table: string;
  comment: string;
  enabled: boolean;
  errors: string[];
  parsing_notes: string[];
}

interface ParsedHost {
  machine_id: string;
  hostname: string;
  os: string;
  external_ip: string;
  internal_ips: string;
  interfaces: HostInterface[];
  routes: HostRoute[];
  interfaces_section: string;
  routes_section: string;
}

interface ParsedNote {
  title: string;
  content: string;
}

interface UploadedFileInfo {
  url: string;
  file_name: string;
  file_path: string;
}

interface IHostParser {
  targetId: string;
  loading: boolean;
  mode: ParserState;
  parsedNote: null | ParsedNote;
  foundHost: null | FoundHost;
  parsedHost: null | ParsedHost;
  uploadedFileInfo: null | UploadedFileInfo;
  saveResult: null | SaveResult;
  errors: string[];
}

const HostParserStore = createStore({
  initialState: {
    targetId: "",
    loading: false,
    mode: ParserState.Init,
    parsedNote: null,
    foundHost: null,
    parsedHost: null,
    uploadedFileInfo: null,
    saveResult: null,
    errors: [],
  } as IHostParser,

  actions: {
    init: (targetId: string) => ({ setState, getState }) => {
      setState({targetId: targetId});
    },
    parseFile: (file: any) => async ({ setState, getState }) => {
      setState({ loading: true });
      let formData = new FormData();
      formData.set("file", file);
      const result = await TARGET_CLIENT.parseHostConfigFile(`${getState().targetId}`, formData);
      setState({
        uploadedFileInfo: result?.data.file_info,
        parsedNote: result?.data.note,
        foundHost: result?.data.found_host,
        parsedHost: result?.data.host,
      });

      if (getState().parsedHost?.machine_id.trim().length === 0) {
        getState().errors.push("Field 'machine id' not found in parsed file, unable to create Host");
      }

      getState().parsedHost?.interfaces.forEach((item: HostInterface) => {
        item.errors = []
        if (item.ip_address.trim().length === 0) {
          item.errors.push("'ip addr' not set")
        } else {
          if (!validateIPAddress(item.ip_address)) {
            item.errors.push("'ip_address' is not a valid IP address: " + item.ip_address);
          }
        }
        if (item.netmask.trim().length === 0) {
          item.errors.push("'netmask' not set")
        } else {
          if (!validateIPAddress(item.netmask)) {
            item.errors.push("'netmask' is not a valid IP address: " + item.ip_address);
          }
        }
        if (item.errors.length > 0) {
          item.enabled = false;
        }
      });

      getState().parsedHost?.routes.forEach((item: HostRoute) => {
        item.errors = []
        if (item.destination.trim().length === 0) {
          item.errors.push("'destination' not set")
        } else {
          if (!validateIPAddress(item.destination)) {
            item.errors.push("'destination' is not a valid IP address: " + item.destination);
          }
        }
        if (item.gateway.trim().length === 0) {
          item.errors.push("'gateway' not set")
        } else {
          if (!validateIPAddress(item.gateway)) {
            item.errors.push("'gateway' is not a valid IP address: " + item.gateway);
          }
        }
        if (item.netmask.trim().length === 0) {
          item.errors.push("'netmask' not set")
        } else {
          if (!validateIPAddress(item.netmask)) {
            item.errors.push("'netmask' is not a valid IP address: " + item.netmask);
          }
        }
        if (item.interface.trim().length === 0) {
          item.errors.push("'interface' not resolved")
        }
        if (item.errors.length > 0) {
          item.enabled = false;
        }
      });

      setState({
        loading: false,
        mode: ParserState.ParseResult,
      });
    },
    resetState: () => async ({ setState, getState }) => {
      setState({
        loading: false,
        mode: ParserState.Init,
        saveResult: null,
        uploadedFileInfo: null,
        parsedNote: null,
        foundHost: null,
        parsedHost: null,
        errors: [],
      });
    },
    saveParsedHost: () => async ({ setState, getState }) => {
      setState({ loading: true });
      const host: any = getState().parsedHost;
      const upsertData = {
        machine_id: host.machine_id,
        hostname: host.hostname,
        os: host.os,
        internal_ips: host.internal_ips,
        external_ip: host.external_ip,
        target_id: getState().targetId,
        interfaces: host.interfaces
          .filter((item: any) => item.enabled)
          .filter((item: any) => item.name && item.ip_address && item.netmask)
          .map((item: any) => ({
            interface_name: item.name,
            address: item.ip_address,
            netmask: item.netmask
          })),
        routes: host.routes
          .filter((item: any) => item.enabled)
          .filter((item: any) => item.destination && item.gateway && item.netmask)
          .map((item: any) => ({
            interface: item.interface,
            destination: item.destination,
            mask: item.netmask,
            gateway: item.gateway,
          })),
      };

      try {
        const hostUpsert = await TARGET_CLIENT.upsertHost(upsertData);
        const note: any = getState().parsedNote;
        const uploadedFile: any = getState().uploadedFileInfo;

        const noteUpsert = await TARGET_CLIENT.upsertNote({
          id: getState().targetId,
          data: {
            content: note.content,
            host_id: hostUpsert.id,
            title: note.title,
            file_path: uploadedFile.file_path,
            file_name: uploadedFile.file_name,
            tag: note.tag,
          }
        });

        setState({
          loading: false,
          mode: ParserState.SaveResult,
          saveResult: {
            host_id: hostUpsert.id,
            hostname: host.hostname,
            note_id: noteUpsert.id,
            note_title: note.title,
            host_updated: getState().foundHost !== null,
            note_updated: noteUpsert.updated,
          }
        });
      } catch (error) {
        getState().errors.push("Error: " + error);
        setState({
          loading: false,
          mode: ParserState.SaveResult,
        });
      }
    },
  },
});

export const HostParserState = createHook(HostParserStore);
