import { Tooltip } from "antd";
import {
  SearchTargetsModel,
  copyToClipboard,
  getFilterOptions,
  onlyUnique,
} from "@/shared";
import { NetcatStore } from "../Store";
import {
  CommentOutlined,
  CopyOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { addComment, removeConnection, showInfoModal } from ".";

export const render_relay = (record: any) =>
  record.type === "mycelium"
    ? record.relay_addr
    : record.relay_addr.includes(":")
    ? record.relay_addr.split(":")[0]
    : record.relay_addr;

export const client_filters = [
  { text: "", value: "" },
  ...getFilterOptions(
    NetcatStore.storeState
      .getState()
      .tabledata?.map((item: any) => (item?.client ? item?.client : ""))
      .filter(onlyUnique)
  ),
];

export const onClientFilter = (value: any, record: any) =>
  value.length === 0
    ? record?.client?.length === 0 || !record?.client
    : record?.client?.includes(value);

export const host_link = (value: any, target_host_id: any) => (
  <a href={`/ui/target-host/${target_host_id}/info`} className="host_link_a">
    {value ? value : "{host}"}
  </a>
);

export const target_link = (
  value: any,
  record: any,
  targetsList: SearchTargetsModel[] | undefined
) => {
  let id = targetsList?.find((i) => i.name === record.target)?.id;
  return id ? (
    <a href={`/ui/target/${id}/info`} className="host_link_a">
      {value ? value : "{target}"}
    </a>
  ) : value ? (
    <>{value}</>
  ) : (
    <></>
  );
};

export const render_actions = (
  record: any,
  setIsRerender?: (isRerender: boolean) => void
) => {
  return (
    <div className="action-btns">
      <Tooltip title="Copy connection string" color="green">
        <CopyOutlined
          id="Copy"
          title="Copy connection string"
          style={{ color: "green" }}
          onClick={(event) =>
            copyToClipboard(
              "Copy",
              record.connection_string,
              "cpd",
              event.clientY
            )
          }
        />
      </Tooltip>
      <InfoCircleOutlined
        title="Info"
        style={{ color: "rgb(74, 74, 245)" }}
        onClick={() => showInfoModal(record)}
      />
      <CommentOutlined
        title="Comment"
        style={{ color: "#b980e1" }}
        onClick={() => {
          addComment(record, setIsRerender);
        }}
      />
      <DeleteOutlined
        onClick={() => {
          removeConnection(record, setIsRerender);
        }}
        title="Delete"
        style={{ color: "red" }}
      />
    </div>
  );
};
