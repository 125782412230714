import React, {useEffect, useState} from 'react';
import {
  Modal, Form, Divider, Typography, Upload, Button, UploadFile, Descriptions, Table, Spin, Row, Col, Alert, Result,
  Tooltip
} from 'antd';
import {
  CheckOutlined, FormOutlined, InfoCircleTwoTone, QuestionCircleOutlined, UploadOutlined
} from '@ant-design/icons';
import { HostParserState, ParserState } from "./HostParserState";
const { Title, Text, Paragraph } = Typography;

type Props = {
  targetId: string;
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  reloadHostList: () => void;
};

const HostParserModal: React.FC<Props> = ({ targetId, isModalOpen, setIsModalOpen, reloadHostList }) => {
  const [state, { init, parseFile, saveParsedHost, resetState }] = HostParserState();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    init(targetId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadFile = async (componentsData: any) => {
    setFileList([]);
    resetState();
    parseFile(componentsData.file);
    componentsData.onSuccess();
  };

  const handleChange = (info: any) => {
    if (info.file.status === "removed") {
      setFileList([]);
    }
  };

  const onClose = () => {
      setIsModalOpen(false);
      setFileList([]);
      resetState();
  };

  const openHost = () => {
    window.open(`/ui/target-host/${state.saveResult?.host_id}/info`, '_blank');
  };

  const saveParsingResults = async () => {
    await saveParsedHost();
    reloadHostList();
  }

  const showOriginalSectionModal = (title: string, text: string) => {
    Modal.info({
      title: title,
      content: (<pre className="code-block">{text}</pre>),
      okText: "Close",
      width: "60%",
    });
  };

  return (
    <Modal
      title="Host Parser"
      open={isModalOpen}
      onCancel={onClose}
      cancelText="Cancel"
      width={"80%"}
      footer={[
        <Button key="cancel" onClick={onClose}>Close</Button>,
      ]}
    >
      <Divider />
      <Row justify="center" align="middle">
        <Col span={8}>
          <Form>
            <Form.Item name="file" style={{ marginBottom: '0' }}>
              <Upload
                customRequest={uploadFile}
                maxCount={1} accept=".txt, .rsc"
                fileList={fileList}
                onChange={handleChange}
              >
                <Button icon={<UploadOutlined />} className="button-with-text">Select file to scan</Button>
              </Upload>
            </Form.Item>
          </Form>
        </Col>
        <Col span={16}>
          <Text>
            <InfoCircleTwoTone /> {" "} Mikrotik RouterOS config file: .rsc, .txt
          </Text>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={state.loading}>
        {state.mode === ParserState.ParseResult && (
          <>
            <Alert message={
              <span>
                File {' '}
                <a href={state.uploadedFileInfo?.url} target="_blank" rel="noopener noreferrer">
                  {state.uploadedFileInfo?.file_name}
                </a>
                {' '} uploaded and parsed successfully!
              </span>
            } type="success"/>

            {state.errors.map((error, index) => (
              <><br/><Alert message={<span>{error}</span>} type="error"/></>
            ))}

            {state.foundHost && (
              <>
                <br/>
                <Alert message={
                  <span>
                    Found existing host{" "}
                    <a href={`/ui/target-host/${state.foundHost.host_id}/info`} target="_blank"
                       rel="noopener noreferrer">
                    {state.foundHost.hostname} / machine ID: {state.foundHost.machine_id}
                  </a>{" "} which will be updated if saving parsing results
                </span>
                } type="warning"/>
              </>
            )}

            <br/>
            <Button type={"primary"} icon={<FormOutlined/>} onClick={saveParsingResults}
              disabled={state.errors.length > 0}>
              Save parsing results as Target Host
            </Button>

            <Title level={5}>Parsing results: Target Host</Title>
            <Descriptions
              bordered={true}
              layout='vertical'
              items={[
                {key: '1', label: 'Machine ID', children: state.parsedHost?.machine_id},
                {key: '2', label: 'OS', children: state.parsedHost?.os},
                {key: '3', label: 'Hostname', children: state.parsedHost?.hostname},
                {key: '4', label: 'ExternalIP', children: state.parsedHost?.external_ip},
                {key: '5', label: 'InternalIPs', children: state.parsedHost?.internal_ips},
              ]}/>

            <Title level={5}>
                Interfaces {" "}
                <Tooltip title="Show original config">
                  <QuestionCircleOutlined onClick={() => showOriginalSectionModal(
                    "Original Interfaces from config",
                    state.parsedHost?.interfaces_section!
                  )}/>
                </Tooltip>
            </Title>
            <Table
              columns={[
                {title: 'Interface Name', dataIndex: 'name', key: 'name', width: '20%',},
                {title: 'Address', dataIndex: 'ip_address', key: 'ip_address', width: '10%',},
                {title: 'Mask', dataIndex: 'netmask', key: 'netmask', width: '10%',},
                {title: 'Network', dataIndex: 'network', key: 'network', width: '20%',},
                {
                  title: 'Comment', dataIndex: 'comment', key: 'comment', width: '30%',
                  render: (value: boolean, row) => (
                    <Paragraph><ul>
                      {value && (<li>{value}</li>)}
                      {row.errors.map((error, index) => (
                        <li key={index}>Validation Error: {error}</li>
                      ))}
                      {row.parsing_notes && row.parsing_notes.map((note, index) => (
                        <li key={index}>Parsing Note: {note}</li>
                      ))}
                    </ul></Paragraph>
                  ),
                },
                {
                  title: 'Enabled', dataIndex: 'enabled', key: 'enabled', width: '10%',
                  render: (value: boolean, row) => value ? <CheckOutlined /> : (
                    <span>{row.errors.length === 0 ? 'disabled' : 'invalid'}</span>
                  ),
                },
              ]}
              dataSource={state.parsedHost?.interfaces.map((item, i) => (
                {...item, key: i})
              )}
              pagination={false}
              rowKey="key"
            />

            <Title level={5}>
              Routes  {" "}
              <Tooltip title="Show original config">
                <QuestionCircleOutlined onClick={() => showOriginalSectionModal(
                  "Original Routes from config",
                  state.parsedHost?.routes_section!
                )}/>
              </Tooltip>
            </Title>
            <Table
              columns={[
                {title: 'Interface Name', dataIndex: 'interface', key: 'interface', width: '20%',},
                {title: 'Destination', dataIndex: 'destination', key: 'destination', width: '10%',},
                {title: 'Mask', dataIndex: 'netmask', key: 'netmask', width: '10%',},
                {title: 'Gateway', dataIndex: 'gateway', key: 'gateway', width: '10%',},
                {title: 'Routing Table', dataIndex: 'routing_table', key: 'routing_table', width: '10%',},
                {
                  title: 'Comment', dataIndex: 'comment', key: 'comment', width: '30%',
                  render: (value: boolean, row) => (
                    <Paragraph><ul>
                      {value && (<li>{value}</li>)}
                      {row.errors.map((error, index) => (
                        <li key={index}>Validation Error: {error}</li>
                      ))}
                      {row.parsing_notes && row.parsing_notes.map((note, index) => (
                        <li key={index}>Parsing Note: {note}</li>
                      ))}
                    </ul></Paragraph>
                  ),
                },
                {
                  title: 'Enabled', dataIndex: 'enabled', key: 'enabled', width: '10%',
                  render: (value: boolean, row) => value ? <CheckOutlined /> : (
                    <span>{row.errors.length === 0 ? 'disabled' : 'invalid'}</span>
                  ),
                },
              ]}
              dataSource={state.parsedHost?.routes.map((item, i) => (
                {...item, key: i})
              )}
              pagination={false}
              rowKey="key"
            />

            <Title level={5}>Note</Title>
            <Descriptions
              bordered={true}
              layout='vertical'
              column={4}
            >
              <Descriptions.Item span={3} label="Title">{state.parsedNote?.title!}</Descriptions.Item>
              <Descriptions.Item span={1} label="File">
                <a href={state.uploadedFileInfo?.url} target="_blank" rel="noopener noreferrer">
                  {state.uploadedFileInfo?.file_name}
                </a>
              </Descriptions.Item>
              <Descriptions.Item label="Content" span={4}>
                <pre className="code-block">
                  {state.parsedNote?.content!}
                </pre>
              </Descriptions.Item>
            </Descriptions>
          </>
        )}

        {state.mode === ParserState.SaveResult && (
          <>
          {state.errors.map((error, index) => (
            <><br/><Alert message={<span>{error}</span>} type="error"/></>
          ))}

          {state.errors.length === 0 && (
            <Result status="success"
              title={(
                <>Successfully {state.saveResult?.host_updated ? "Updated" : "Created"} Target Host{" "}
                <a href={`/ui/target-host/${state.saveResult?.host_id}/info`} target="_blank" rel="noopener noreferrer">
                  {state.saveResult?.hostname || state.saveResult?.host_id}
                </a>{" "}!</>
              )}
              subTitle={(
                <>Host note {state.saveResult?.note_updated ? "updated" : "added"}: {' '}
                <a href={`/ui/target/${targetId}/note/${state.saveResult?.note_id}`} target="_blank" rel="noopener noreferrer">
                  {state.saveResult?.note_title}
                </a></>
              )}
              extra={[<Button type="primary" key="openHost" onClick={openHost}>Open host</Button>,]}
            />
          )}
          </>
        )}
      </Spin>
    </Modal>
  );
};

export default HostParserModal;
