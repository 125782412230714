import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import {FloatButton, Input, List, Spin, Tooltip} from "antd";

import { TargetContext } from "@/Components/shared";
import { useMessagesState } from "./Store";
import "./styles.css";
import { AddMsgModule, DateSection, TopSection } from "./ui";
import { countRows, updateMsgsInfo } from "./actions";
import {debounce, getCookie} from "@/shared";
import { DownOutlined } from "@ant-design/icons";
import Spinner from "@/shared/components/Spinner/Spinner";

const { TextArea } = Input;

const MessagesTable = () => {
  const routeParams = useParams();
  const [state, actions] = useMessagesState();
  const { targetsList } = useContext(TargetContext)!;
  const token = getCookie("token") as string;
  const [offset, setOffset] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onScroll = (event: any) => {
      setOffset(window.scrollY);
    };

    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (document.body.scrollHeight - 100 < window.scrollY + window.innerHeight && !state.loading && state.hasMore) {
        actions.fetchMessages(false);
      }
    };

    const debouncedHandleScroll = debounce(handleScroll, 300)

    window.removeEventListener("scroll", debouncedHandleScroll);
    window.addEventListener("scroll", debouncedHandleScroll);
    return () => window.removeEventListener("scroll", debouncedHandleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions]);

  useEffect(() => {
    actions.setBeacon(`${routeParams.id}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeParams]);

  useEffect(() => {
    document.title = `${state.beacon?.target_host?.hostname} / ${state.beacon?.target_host?.target}/ Beacons messages / BartUI `;
  }, [state.beacon]);

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;
    if (state.msgsInProgress?.length !== 0) {
      interval = setInterval(() => {
        updateMsgsInfo();
      }, 30 * 1000);
      return () => {
        clearInterval(interval);
      };
    } else {
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.msgsInProgress]);

  return token ? (
    <div ref={ref} className="messages_table msg_bg">
      <div
        style={{
          position: offset > 25 ? "fixed" : "relative",
          top: offset > 25 ? 60 : 20,
          borderBottom: offset > 25 ? "1px solid rgba(255,255,255,.3)" : "none",
          width: "96.5vw",
          background: "#000",
          zIndex: 1,
        }}
      >
        <TopSection targetsList={targetsList} />
        <div className="header">
          <AddMsgModule id={`${routeParams.id}`} />
        </div>
        <Tooltip title="scroll to top" color="green">
          <DownOutlined
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            style={{ display: offset > 25 ? "block" : "none" }}
            className="show_scroll"
          />
        </Tooltip>
      </div>
      {routeParams.id === state.beacon?.id && (
        <div
          className="executed_commands"
          style={{ marginBottom: offset > 30 ? "100px" : 0 }}
        >
          <List dataSource={state.messages}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <List.Item.Meta title={item.request}
                  description={
                    <details open className="msg_details">
                      <summary className="item_header">
                        <div className="left">
                          <p>
                            {" "}
                            <b>
                              {" "}
                              {item?.user_login ? `User: ${item?.user_login}` : ""}
                            </b>
                          </p>

                          <h3
                            style={{
                              color: item.reply.includes("ERROR")
                                ? "red"
                                : !item?.processed_at
                                  ? "yellow"
                                  : "green",
                              marginBlockEnd: item?.processed_at ? "0" : "1em",
                            }}
                          >
                            {" "}
                            {"#"} {item.request}
                          </h3>
                        </div>
                        <DateSection
                          item={item}
                          beacon={state.beacon}
                          index={state.messages.length}/>
                      </summary>
                      {countRows(item.reply) > 0 ? (
                        item.request === "take-screenshot" ? (
                          <img
                            src={item.reply}
                            alt={item.reply}
                            width="1024"
                            height="768"
                          ></img>
                        ) : (
                          <section>
                            {" "}
                            <TextArea
                              readOnly
                              rows={
                                countRows(item.reply + 1) < 20
                                  ? countRows(item.reply + 1)
                                  : 20
                              }
                              style={{
                                overflow:
                                  countRows(item.reply + 1) < 20
                                    ? "auto"
                                    : "scroll",
                              }}
                              className="messagesTextArea"
                              value={item.reply}
                            />{" "}
                          </section>
                        )
                      ) : (
                        <section>
                          {" "}
                          <br/>
                        </section>
                      )}
                    </details>
                  }
                />
              </List.Item>
            )}
          />
          {state.loading && (
            <Spin tip="Loading..." size="large">
              <pre>{""}</pre>
            </Spin>
          )}
          </div>
        )}
        <FloatButton.BackTop/>
        <Spinner loading={!state.beacon} />
    </div>
  ) : (
    <></>
  );
};

export default MessagesTable;
