import { CheckOutlined, DeleteOutlined, PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { DateCorrector } from "@/shared";

const date_sorter = (a: any, b: any) => new Date(a).getTime() - new Date(b).getTime()
const render_expired_time = (date: any) => {
  let time = new Date(date).getTime() - new Date().getTime();
  return time < 0 ? 'Expired' : time / (1000 * 60 * 60 * 24) > 1 ?
    <>{Math.round(time / (1000 * 60 * 60 * 24))} days</> :
    <>{Math.round(time / (1000 * 60 * 60))} hrs</>
}

const render_action = (record: any,
  deleteToken: (id: string) => Promise<void>,
  switchToken: (id: string) => Promise<void>) => <>
    <Tooltip title={record?.active ? "Deactivate" : "Activate"} color={record?.active ? "purple" : 'green'}>
      {
        record?.active ?
          <PauseCircleOutlined onClick={() => { switchToken(record.id) }}
            style={{ color: 'purple', marginLeft: "22px", cursor: 'pointer', fontSize: '19px' }} /> :
          <PlayCircleOutlined onClick={() => { switchToken(record.id) }}
            style={{ color: 'green', marginLeft: "22px", cursor: 'pointer', fontSize: '19px' }} />
      }
    </Tooltip>
    <DeleteOutlined title="Delete" onClick={async () => { deleteToken(record.id); }}
      style={{ color: "red", marginLeft: "22px", padding: "5px" }} />
  </>


export const getEditTokenCols = (
  deleteToken: (id: string) => Promise<void>,
  switchToken: (id: string) => Promise<void>) => {
  return [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (value: string, record: any) => value.includes('binary') ? <a href={`/ui/binary/${record.id}/info`}>{value}</a> : value
    },
    {
      title: "Server",
      dataIndex: "server",
      key: "server",
      width: '15%',
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (active: boolean) => active && <CheckOutlined />
    },
    {
      title: "Binary kind",
      dataIndex: "binary_kind",
      key: "binary_kind",
    },
    {
      title: "Binary version",
      dataIndex: "binary_version",
      key: "binary_version",
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: '15%',
      render: (tags: string[]) => tags?.toString(),
    },
    {
      title: "Expires",
      dataIndex: "expires_at",
      key: "expires_at",
      render: (expires_at: any) => render_expired_time(expires_at),
      sorter: (a: { expires_at: any }, b: { expires_at: any }) => date_sorter(a.expires_at, b.expires_at)
    },
    {
      title: "Last used at",
      dataIndex: "last_used_at",
      key: "last_used_at",
      render: (last_used_at: any) => { return last_used_at ? <DateCorrector date={new Date(last_used_at)} /> : <></> },
      sorter: (a: { last_used_at: any }, b: { last_used_at: any }) => date_sorter(a.last_used_at, b.last_used_at)
    },
    {
      title: "Payload",
      dataIndex: "payload",
      key: "payload",
    },
    {
      title: 'Actions',
      dataIndex: "Actions",
      key: "Actions",
      render: (_text: any, record: any) => render_action(record, deleteToken, switchToken)
    },
  ] as ColumnsType<any>
}
