import JSZip from "jszip";
import { CredentialsApi, TargetApi } from "@/Api";
import { CredsStore } from "../Store";
import { saveAs } from 'file-saver';
import { Modal } from "antd";
import { CredentialModel } from "@/shared";
import { AddCredFormFields } from "../ui/AddCredModal";
import { AddCredentialsFromUI } from "../models/AddCredentialsUI.model";

const CREDS_SERVICE = new CredentialsApi();
const TARGET_SERVICE = new TargetApi();

export const editCreds = async (data: any,  fn?: () => void, hostCreds?: CredentialModel[]) => {
    await CREDS_SERVICE.updateCreds(data);
    fn ? fn(): CredsStore.actions.setTableData(hostCreds);
}

export const addTargetCreds = async (targetId: string, formData: AddCredFormFields) => {
    const data = new AddCredentialsFromUI(formData);
    const updatedCreds: CredentialModel[] = await TARGET_SERVICE.addTargetCreds(targetId, data);
    CredsStore.actions.setTableData(updatedCreds);
}

export const addHostCreds = async (hostId: string, formData: AddCredFormFields) => {
    const data = new AddCredentialsFromUI(formData);
    const updatedCreds: CredentialModel[] = await TARGET_SERVICE.addHostCreds(hostId, data);
    CredsStore.actions.setTableData(updatedCreds);
}

export const removeCreds = async (id: string, fn: () => void) => {
    await CREDS_SERVICE.deleteCreds(id);
    fn();
}

export const setEditedModel = (record: any) => {
    CredsStore.actions.setEdited(record);
    CredsStore.actions.setEditing(true);
}

export const downloadSelectedKeys = () => {
    const state = CredsStore.storeState.getState();
    let keys = state.tabledata.filter(i => {
        return (state.selectedRowKeys as string[]).indexOf(i.id!) !== -1 && i.key?.length !== 0;
    }).map(i => { return { key: i.key, id: i.id, login: i.login, host: i.target_host } })

    keys = uniq(keys);
    keys.length > 0 ?
        generateZip(keys) :
        Modal.error({
            title: 'The key field for all selected rows is empty'
        })
}

export const generateZip = (data: any) => {
    var zip = new JSZip();
    data.forEach((item: any, index: number) => {
        zip.file(`${item.login}_${item.host}_${item.id}`, item.key)
        if (index + 1 === data.length) {
            zip.generateAsync({ type: 'blob' }).then((content) => {
                saveAs(content, 'keys');
            });
        }
    })
}

const uniq = (arr: any[], track = new Set()) =>
    arr.filter(({ key }) => (track.has(key) ? false : track.add(key)));


export const check_data = (data: any) => {
    if (!data) {
        let modal = Modal.error({
            title: 'No data found for the requested parameters'
        })
        setTimeout(modal.destroy, 2 * 1000);
    }
    return data ? data : []
}
