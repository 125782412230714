import { CloseOutlined } from "@ant-design/icons";
import { Form } from "antd";
import Drawer from "antd/es/drawer";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect } from "react";
import { NodeInfo } from "@/shared";

type Props = {
  nodeInfo: NodeInfo | undefined;
  setNodeInfo: (nodeInfo: NodeInfo | undefined) => void;
  open: boolean;
  setOpen: (isDrawerOpen: boolean) => void;
};

export const InfoDrawer: React.FC<Props> = ({
  nodeInfo,
  setNodeInfo,
  open,
  setOpen,
}) => {
  const [form] = Form.useForm();

  const clear = () => {
    setNodeInfo(undefined);
    form.resetFields();
    setOpen(false);
  };
  useEffect(() => {
    form.setFieldsValue(nodeInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeInfo]);

  return (
    <Drawer
      style={{ backgroundColor: "var(--primary)", position: "relative" }}
      title={"Node: " + nodeInfo?.name}
      placement={"left"}
      closable={true}
      onClose={clear}
      open={open}
      key={"left"}
      closeIcon={<CloseOutlined onClick={clear} />}
    >
      <Form form={form} initialValues={nodeInfo} layout="vertical">
        <FormItem
          className="info"
          label={"Node"}
          name={"name"}
          style={{ marginBottom: "10px" }}
        >
          {(nodeInfo?.node_type === "host" ||
            nodeInfo?.node_type === "gateway") &&
          nodeInfo.access !== "none" ? (
            <a
              href={`/ui/target-host/${nodeInfo.id}/info`}
              rel="noreferrer noopener"
              target="_blank"
            >
              {nodeInfo.name}
            </a>
          ) : (
            <span style={{ color: "var(--header-text-color)" }}>
              {nodeInfo?.name}
            </span>
          )}
        </FormItem>
        <FormItem className="info" label={"Addresses"} name={"addresses"}>
          <TextArea style={{ resize: "none" }} readOnly rows={7} cols={41} />
        </FormItem>
        <FormItem label={"Routes"} className="info" name={"routes"}>
          <TextArea style={{ resize: "none" }} readOnly rows={7} cols={41} />
        </FormItem>
        <FormItem label={"Processes"} className="info" name={"processes"}>
          <TextArea style={{ resize: "none" }} readOnly rows={5} cols={41} />
        </FormItem>
      </Form>
    </Drawer>
  );
};
