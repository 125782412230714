import { TabsProps } from "antd/es/tabs";
import { Network } from ".";
import Notes from "../../Notes/Notes";
import NetworkGraph from "../TargetInfo/Graph/NetworkGraph";
import NetcatTablePage from "../../NetcatTable/NetcatTablePage";
import { TargetStore } from "../Store";
import BeaconTable from "../../Beacon/BeaconTable";
import DataTable from "../../Table/Table";
import CredentialsPage from "../../CredentialsPage/CredentialsPage";
import HostCmdInfo from "../../CMDPage/HostCmdInfo";

export const get_tabs = (id: string | undefined) => {
  return [
    {
      key: "",
      label: "Hosts",
      children: <Network id={id} />,
    },
    {
      key: "#map",
      label: "Jungle map",
      children: <NetworkGraph id={id} />,
    },
    {
      key: "#notes",
      label: "Notes",
      children: TargetStore.storeState.getState().tab.includes("notes") && (
        <Notes id={id} isHostPage={false} target_id={id}/>
      ),
    },
    {
      key: "#sessions",
      label: "Sessions",
      children: TargetStore.storeState.getState().tab.includes("sessions") && (
        <NetcatTablePage
          id={id}
          setIsRerender={TargetStore.actions.setIsRerender}
          colsType="targetInfo"
        />
      ),
    },
    {
      key: "#beacons",
      label: "Beacons",
      children: TargetStore.storeState.getState().tab.includes("beacons") && (
        <BeaconTable
          id={id}
          setIsRerender={TargetStore.actions.setIsRerender}
          colsType="targetInfo"
          showPaging={false}
        />
      ),
    },
    {
      key: "#collection",
      label: "Collection results",
      children: TargetStore.storeState
        .getState()
        .tab.includes("collection") && (
        <DataTable
          id={id}
          colsType="targetInfo"
          setIsRerender={TargetStore.actions.setIsRerender}
        />
      ),
    },
    {
      key: "#credentials",
      label: "Credentials",
      children: TargetStore.storeState
        .getState()
        .tab.includes("credentials") && (
        <CredentialsPage
          targetId={id}
          setRender={TargetStore.actions.setIsRerender}
          colsType="targetInfo"
        />
      ),
    },
    {
      key: "#commands",
      label: "Commands",
      children: <HostCmdInfo target_id={id} />,
    },
  ] as TabsProps["items"];
};
