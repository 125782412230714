import { createHook, createStore, defaultRegistry } from "react-sweet-state";
import { ITargetState } from "./ITargetState";
import { actions } from "./Actions";
import { FAVORITE_KEYS, getCookie } from "@/shared";

const initialState: ITargetState = {
  tabledata: [],
  hosts: undefined,
  favorites: getCookie(FAVORITE_KEYS.favoriteTargets)
    ? getCookie(FAVORITE_KEYS.favoriteTargets)!
    : "",
  isRerender: false,
  isHostTable: false,
  isEdit: false,
  targetDetails: undefined,
  tab: "",
  collapseKeys: [],
  hostCollapse: [],
  listStyle: getCookie("listStyle") as string,
  notes: [],
  isDirtyNote: false,
  filters: undefined,
  isContext: false,
  contextPosition: undefined,
  record: undefined,
  loading: false,
};

export const Store = createStore<ITargetState, typeof actions>({
  initialState,
  actions,
});

export const useTargetState = createHook(Store);

export const TargetStore = defaultRegistry.getStore(Store);
