import React from 'react';
import './Spinner.css';
import {ClipLoader} from "react-spinners";

interface SpinnerProps {
  loading?: boolean;
  size?: number;
}

const Spinner: React.FC<SpinnerProps> = ({loading = true, size = 180}) => {
  const spinnerRadius: number = size / 2;

  const spinnerStyle = {
    top: `calc(50% - ${spinnerRadius}px)`,
    left: `calc(50% - ${spinnerRadius}px)`,
  };

  return (
      <ClipLoader
        loading={loading}
        className="spinner"
        size={size}
        cssOverride={spinnerStyle}
      />
  );
};

export default Spinner;
