import { SettingOutlined } from "@ant-design/icons";
import { FloatButton, Table, Tooltip } from "antd";
import React, { useEffect } from "react";
import { getCookie, pagination_options } from "@/shared";
import { TargetContext } from "../shared";
import { useBinaryState } from "./Store";
import "./styles.css";
import { BBDrawer, bynary_columns } from "./ui";
import { getTableData } from "./actions";

const BinaryTable: React.FC = () => {
  const token = getCookie("token") as string;
  const [state, actions] = useBinaryState();
  const { targetsList } = React.useContext(TargetContext)!;

  useEffect(() => {
    !state.isFormOpen && getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isFormOpen]);

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;
    if (state.hasEmpty) {
      interval = setInterval(() => {
        getTableData();
      }, 10 * 1000);
      return () => {
        clearInterval(interval);
      };
    } else {
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.hasEmpty]);

  useEffect(() => {
    document.title = "Binary / BartUI ";
  }, []);

  return !token ? (
    <></>
  ) : (
    <div className="flex_content binary_main">
      <span className="copy_reminder">
        * Double click on the Result field to copy file url
      </span>
      <Tooltip title="Build binary">
        <SettingOutlined
          className="StartOpenBtn"
          title="Build binary"
          onClick={() => actions.setIsFormOpen(!state.isFormOpen)}
        />
      </Tooltip>
      <div className="contentTable">
        <Table
          scroll={{ x: 1700 }}
          className="binaryTable"
          columns={bynary_columns(state.tabledata, targetsList)}
          dataSource={state.tabledata}
          pagination={pagination_options}
          rowKey="id"
        />
      </div>
      <BBDrawer targetsList={targetsList} />
      <FloatButton.BackTop />
    </div>
  );
};
export default BinaryTable;
