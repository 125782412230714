import { ColumnsType } from "antd/es/table";
import {
  CredentialModel,
  Targets,
  actionsType,
  getCache,
  getCookie,
  setCache,
} from "@/shared";
import { ICredsState } from "./ICredsState";
import { CredentialsApi } from "@/Api";
import AuthLocalStorage from "@/AuthLocalStorage";
import { check_data } from "../actions";
import {
  ColumnGroupType,
  ColumnType,
  SortOrder,
} from "antd/es/table/interface";

const CREDS_SERVICE = new CredentialsApi();

export const actions = {
  setTableData:
    (creds?: CredentialModel[], id?: string) =>
    async ({ setState, getState }: actionsType<ICredsState>) => {
      setState({ ...getState(), loading: true });
      let target = AuthLocalStorage.getCredsSelects()
        ? JSON.parse(AuthLocalStorage.getCredsSelects()!)
        : new Targets();
      if (id) {
        target.target_id = id;
      }
      let tabledata: CredentialModel[] = creds
        ? creds
        : check_data(
            await CREDS_SERVICE.getCreds({
              source: target.type === "all" ? "" : target.type,
              target_id: target.target_id,
              search_phrase: target.search_phrase,
            })
          );
      setState({ ...getState(), tabledata, loading: false });
    },

  setAdding:
    (isAdding: boolean) =>
    ({ setState, getState }: actionsType<ICredsState>) =>
      setState({ ...getState(), isAdding }),

  setEditing:
    (isEditing: boolean) =>
    ({ setState, getState }: actionsType<ICredsState>) =>
      setState({ ...getState(), isEditing }),

  setEdited:
    (edited: CredentialModel) =>
    ({ setState, getState }: actionsType<ICredsState>) =>
      setState({ ...getState(), edited }),

  setCurrentPage:
    (currentPage: number) =>
    ({ setState, getState }: actionsType<ICredsState>) =>
      setState({ ...getState(), currentPage }),

  setSources:
    () =>
    async ({ setState, getState }: actionsType<ICredsState>) => {
      let types = await getCache("credsSourses");
      let sources: string[] = [];
      sources =
        types?.length === 0 ? await CREDS_SERVICE.getCredsSource() : types;
      types?.length === 0 && setCache(sources, "credsSourses");

      setState({ ...getState(), sources });
    },

  setSelectedColumns:
    (selectedColumns: ColumnsType<any>) =>
    ({ setState, getState }: actionsType<ICredsState>) => {
      setState({ ...getState(), selectedColumns });
    },

  setSelectedRowKeys:
    (selectedRowKeys: never[]) =>
    ({ setState, getState }: actionsType<ICredsState>) =>
      setState({ ...getState(), selectedRowKeys }),

  changeSelectedColumnsSorting:
    (sorter?: string[], cookie_name?: string) =>
    ({ setState, getState }: actionsType<ICredsState>) => {
      let sort = sorter ? sorter : getCookie(`${cookie_name}`)?.split(",");
      let selectedColumns: (
        | ColumnGroupType<CredentialModel>
        | ColumnType<CredentialModel>
      )[] = [];
      selectedColumns = sort
        ? [
            ...getState().selectedColumns.map((col: any) => {
              if (sort && col.key === sort[0]) {
                col.sortOrder =
                  sort[1] === "undefined" ? null : (sort[1] as SortOrder);
              } else {
                col.sortOrder = undefined;
              }
              return col;
            }),
          ]
        : getState().selectedColumns;
      setState({ ...getState(), selectedColumns });
    },
};
