import { useEffect } from "react";

import { ConfigProvider, Select } from "antd";
import { ColumnsType } from "antd/es/table";
import { SortOrder } from "antd/es/table/interface";

import {
  getOptions,
  getCookie,
  setCookie,
  SearchTargetsModel,
} from "@/shared";

type Props = {
  tabledata: any[];
  targetsList: SearchTargetsModel[];
  columns: {
    columns: ColumnsType<any>;
    column_names: string[];
  };
  selected_values: string[];
  cookie_name: string;
  setSelectedColumns:
    | React.Dispatch<React.SetStateAction<ColumnsType<any>>>
    | ((selectedColumns: ColumnsType<any>) => void);
};

export const ColumnSelector: React.FC<Props> = ({
  tabledata,
  targetsList,
  columns,
  selected_values,
  cookie_name,
  setSelectedColumns,
}) => {
  const DEFAULT_COLUMNS = ["Host name", "Actions", "Login", "Password", ""];

  const set_columns = () => {
    let cols = getCookie(cookie_name);
    if (cols && cols?.length > 0) {
      change_columns(cols?.split(","));
    } else {
      change_columns(columns.column_names);
    }
  };

  const change_columns = (value: string[]) => {
    if (value.indexOf("all") > -1) {
      setSorter(columns.columns);
      setCookie(cookie_name, columns.column_names!.join(","), 100);
    } else {
      let selected = columns.columns?.filter((item) => {
        return value?.includes(item.title!.toString());
      });
      setSorter(selected);
      setCookie(cookie_name, value!.join(","), 100);
    }
  };

  const setSorter = (selectedColumns: any) => {
    let sort = getCookie(`${cookie_name}_sorter`)?.split(",");
    if (sort) {
      setSelectedColumns(
        selectedColumns.map((col: any) => {
          if (sort && col.key === sort[0]) {
            col.sortOrder =
              sort[1] === "undefined" ? null : (sort[1] as SortOrder);
          } else {
            col.sortOrder = undefined;
          }
          return col;
        })
      );
    } else {
      setSelectedColumns(selectedColumns);
    }
  };

  useEffect(() => {
    set_columns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetsList, tabledata]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            optionFontSize: 12,
          },
        },
      }}
    >
      <Select
        size="small"
        maxTagCount="responsive"
        mode="multiple"
        style={{ width: "100%", fontSize: "12px", overflow: "hidden" }}
        options={getOptions(
          ["all"].concat(
            columns.column_names.filter(
              (title) => !DEFAULT_COLUMNS.includes(title)
            )
          )
        )}
        placeholder="select columns"
        value={selected_values}
        onChange={(value: string[]) => {
          change_columns(value);
        }}
      />
    </ConfigProvider>
  );
};
