import { useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Popover,
  Select,
} from "antd";
import TextArea from "antd/es/input/TextArea";

import { CredentialModel, SearchTargetsModel } from "@/shared";
import { getHeaderTargetSelects } from "../../shared";
import { useCredsState } from "../Store";
import { editCreds } from "../actions";
import { CloseOutlined } from "@ant-design/icons";

type Props = {
  targetsList: SearchTargetsModel[];
  hostCreds?: CredentialModel[];
  fn?: () => void;
};

export const EditCredModal = ({ targetsList, hostCreds, fn }: Props) => {
  const [form] = Form.useForm();
  const [state, actions] = useCredsState();
  const [isPopoverHidden, setPopoverHidden] = useState(true);

  const handleSubmit = () => {
    form.validateFields().then(
      (result) => {
        if (!result.password && !result.password_hash && !result.key) {
          setPopoverHidden(false);
          return;
        }
        if (state.edited) {
          let edited = { id: state.edited.id, ...form.getFieldsValue() };
          edited.target_id = state.edited.target_id;
          edited.target_host_id = state.edited.target_host_id;

          edited.expired_at = !edited.expired_at || edited.expired_at.trim() === "" ? null : edited.expired_at
          edited.last_used = !edited.expired_at || edited.last_used.trim() === "" ? null : edited.last_used

          editCreds(edited, fn, hostCreds).then(() => {
            message.success("Credentials edited", 2.5);
            clear();
          });
        }
      },
      (error) => {
        message.error(error.message, 2.5);
      }
    );
  };

  const clear = () => {
    actions.setEdited(new CredentialModel());
    actions.setEditing(false);
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue(state.edited);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.edited]);

  return (
    <Modal
      forceRender
      width={500}
      className="manageUserModal"
      title={"Edit credentials"}
      open={state.isEditing}
      onOk={handleSubmit}
      onCancel={clear}
    >
      <Form layout="horizontal" name="AddNewCreds" form={form}>
        <Form.Item label="Service" name="service">
          <Input placeholder="Enter service" />
        </Form.Item>
        <Form.Item label="Login" name="login" rules={[{ required: true }]}>
          <Input placeholder="Enter login" />
        </Form.Item>
        {!hostCreds && (
          <Form.Item label={"Target"} name={"target"}>
            <Select
              showSearch={true}
              options={getHeaderTargetSelects(targetsList)}
              placeholder="select target"
            />
          </Form.Item>
        )}
        <Popover
          open={!isPopoverHidden}
          content={
            <>
              <span>Please fill one of the following fields </span>
              <Button
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => setPopoverHidden(true)}
              />
            </>
          }
        >
          <Divider style={!isPopoverHidden ? { borderColor: "red" } : {}} />
        </Popover>
        <Form.Item label="Password" name="password">
          <Input placeholder="Enter password" />
        </Form.Item>
        <Form.Item label="Password Hash" name="password_hash">
          <Input placeholder="Enter password hash" />
        </Form.Item>
        <Form.Item label="Key" name="key">
          <Input placeholder="Enter key" />
        </Form.Item>
        <Divider />
        <Form.Item label="Last used" name="last_used">
          <Input placeholder="Enter last used date" />
        </Form.Item>
        <Form.Item label="Expired" name="expired_at">
          <Input placeholder="Enter expired date" />
        </Form.Item>
        <Form.Item label="Cmd" name="cmd">
          <Input placeholder="Enter cmd" />
        </Form.Item>
        <Form.Item label="Comment" name="comment">
          <TextArea rows={3} placeholder={`Enter comment`} />
        </Form.Item>
        <Form.Item label={"Favorite"} name={"favorite"} valuePropName="checked">
          <Checkbox />
        </Form.Item>
      </Form>
    </Modal>
  );
};
