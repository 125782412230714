import { Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import React from "react";
import {
  DateCorrector,
  Tabledata,
  getFilterOptions,
  onlyUnique,
  sortOptions,
} from "@/shared";
import { TargetContext } from "../../shared";
import { RenderActions } from "./RenderActions";

export const Table_columns = (tabledata: any[], colsType?: string) => {
  const { targetsList } = React.useContext(TargetContext)!;
  return (
    [
      colsType !== "hostInfo"
        ? {
            title: "Hostname",
            dataIndex: "hostname",
            key: "hostname",
            width: "10%",
            filters: sortOptions(
              getFilterOptions(
                tabledata?.map((x) => x.hostname)?.filter(onlyUnique)
              )
            ),
            filterSearch: true,
            onFilter: (value: any, record: any) =>
              record.hostname.includes(value),
            render: (value: any, record: any) => {
              return (
                <>
                  <a
                    href={`/ui/target-host/${record.target_host_id}/info`}
                    className="host_link_a"
                  >
                    {value}{" "}
                  </a>
                </>
              );
            },
          }
        : null,
      {
        title: "File name",
        dataIndex: "filename",
        key: "fileName",
        filters: sortOptions(
          getFilterOptions(
            tabledata?.map((x) => x.filename)?.filter(onlyUnique)
          )
        ),
        filterSearch: true,
        onFilter: (value: any, record: any) => record.filename.includes(value),
      },
      {
        title: "Tags",
        dataIndex: "tags",
        key: "Tags",
        filters: sortOptions(
          getFilterOptions(
            tabledata
              ?.flatMap((x) => x.tags)
              .filter((x) => {
                x?.replace(/ /g, "");
                return x?.length > 0;
              })
              ?.filter(onlyUnique)
          )
        ),
        filterSearch: true,
        render: (value: any, record: any) => record.tags?.toString(),
        onFilter: (value: any, record: any) => record.tags?.includes(value),
      },
      {
        title: "Version",
        dataIndex: "collector_version",
        key: "Version",
      },
      colsType !== "targetInfo"
        ? {
            title: "Target",
            dataIndex: "target",
            key: "target",
            render: (value: any, record: any) => {
              let id = targetsList?.find((i) => i.name === record.target)?.id;
              return id ? (
                <a href={`/ui/target/${id}/info`}>
                  {value ? value : "{target}"}{" "}
                </a>
              ) : value ? (
                <>{value}</>
              ) : (
                <></>
              );
            },
            sorter: (a: { target: string }, b: { target: string }) =>
              a.target?.toLowerCase() > b.target?.toLowerCase()
                ? 1
                : b.target?.toLowerCase() > a.target?.toLowerCase()
                ? -1
                : 0,
          }
        : null,
      {
        title: "Created",
        dataIndex: "created_at",
        key: "Created",
        render: (created_at: any) => {
          return (
            <Tooltip
              placement="topLeft"
              title={new Date(created_at.Time).toString()}
            >
              <DateCorrector date={new Date(created_at.Time)} />
            </Tooltip>
          );
        },
        sorter: (a: { created_at: any }, b: { created_at: any }) =>
          moment(a.created_at.Time).valueOf() -
          moment(b.created_at.Time).valueOf(),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: 170,
        render: (_text: any, record: any) => <RenderActions record={record} />,
        fixed: "right",
      },
    ] as ColumnsType<Tabledata>
  ).filter((i) => i !== null);
};
