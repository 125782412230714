import {Button, Col, Flex, Row, Tabs} from "antd";
import React, {useEffect, useLayoutEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TargetApi } from "@/Api";
import { getCookie } from "@/shared";
import { useTargetState } from "../Store";
import { get_tabs } from "../ui";
import { getTargetDetails } from "../actions";
import { InfoHeader } from "../ui";
import { Rollback } from "../../shared";
import HostParserModal from "./HostParser/HostParser";
import {ImportOutlined} from "@ant-design/icons";

const TARGET_CLIENT = new TargetApi();

const TargetInfoPage = () => {
  const [state, actions] = useTargetState();
  const token = getCookie("token") as string;
  const routeParams = useParams();
  const navi = useNavigate();
  const { hash } = window.location;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const getHostList = async () => {
    actions.setLoading(true);
    let results = await TARGET_CLIENT.getTargetHostsList(
      `${routeParams.id}`,
      state.isHostTable
    );
    results && actions.setHosts(results);
    actions.setLoading(false);
  };

  useEffect(() => {
    if (state.isRerender) {
      getTargetDetails(routeParams.id, state.isRerender);
      actions.setIsRerender(false);
      getHostList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isRerender, state.targetDetails]);

  useLayoutEffect(() => {
    getTargetDetails(routeParams.id, state.isRerender);
    getHostList();
    routeParams.id !== state.targetDetails?.id && actions.setTab("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeParams.id]);

  useEffect(() => {
    state.tab.length === 0 && getHostList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tab, state.isHostTable]);

  useEffect(() => {
    document.title = state.targetDetails?.name
      ? state.targetDetails?.name + " / BartUI"
      : document.title;
  }, [state.targetDetails?.name]);

  useEffect(() => {
    actions.setTab(hash);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  const parseHost = () => {
    setIsModalOpen(true);
  };

  if (!token) return null;
  return (
    <div className="messages_table">
      <Row>
        <Col span={16}>
          <InfoHeader/>
        </Col>
        <Col span={8}>

          <Flex justify="flex-end" style={{marginBottom: "20px"}}>
            <div className="info_header_btns">
                <Button
                  className="button-with-text"
                  onClick={() => {
                  parseHost();
                  }}
                >
                Add Mikrotik host
                <ImportOutlined
                  title="Parse host from Mikrotik .rsc config"
                  style={{color: "green", paddingRight: "0px"}}
                />
                </Button>
                <Rollback title="back to targets list" delta={"/ui/targets"}/>
              </div>
          </Flex>
        </Col>
      </Row>
      <Tabs
        style={{color: "var(--table-text-color)"}}
        activeKey={state.tab}
        items={get_tabs(routeParams.id)}
        onChange={(key) => {
          actions.setTab(key);
          navi(key);
        }}
      />
      <HostParserModal
        targetId={routeParams?.id!} isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        reloadHostList={getHostList}
      />
    </div>
  );
};

export default TargetInfoPage;
