import { Table, TableColumnsType } from "antd";
import {
  columnSorter,
  collection_result_analysis,
  renderDate,
  date_sorter,
} from "@/shared";
import { CheckOutlined } from "@ant-design/icons";

export const patches_cols = [
  {
    title: "Patched bin",
    dataIndex: "patched_bin",
    key: "patched_bin",
    width: "10%",
    sorter: (a: { patched_bin: string }, b: { patched_bin: string }) =>
      columnSorter(a.patched_bin, b.patched_bin),
  },
  {
    title: "Addresses",
    dataIndex: "addresses",
    key: "addresses",
    width: "10%",
    render: (text: any, record: any) => {
      return <>{record.addresses.join(", ")}</>;
    },
  },
  {
    title: "Comment",
    dataIndex: "comment",
    key: "comment",
    width: "15%",
  },
  {
    title: "Created at",
    dataIndex: "created_at",
    key: "created_at",
    width: "8%",
    render: (created_at: any) => renderDate(created_at),
    sorter: (a: { created_at: any }, b: { created_at: any }) =>
      date_sorter(a.created_at, b.created_at),
  },
  {
    title: "Updated at",
    dataIndex: "updated_at",
    key: "updated_at",
    width: "8%",
    render: (updated_at: any) => renderDate(updated_at),
    sorter: (a: { updated_at: any }, b: { updated_at: any }) =>
      date_sorter(a.updated_at, b.updated_at),
  },
];

export const connections_cols = [
  {
    title: "Path",
    dataIndex: "path",
    key: "path",
    width: "25%",
    render: (path: string[]) => path.join(", "),
    sorter: (a: { path: string }, b: { path: string }) =>
      columnSorter(a.path, b.path),
  },
  {
    title: "Comment",
    dataIndex: "comment",
    key: "comment",
    width: "15%",
  },
  {
    title: "Created at",
    dataIndex: "created_at",
    key: "created_at",
    width: "8%",
    render: (created_at: any) => renderDate(created_at),
    sorter: (a: { created_at: any }, b: { created_at: any }) =>
      date_sorter(a.created_at, b.created_at),
  },
  {
    title: "Updated at",
    dataIndex: "updated_at",
    key: "updated_at",
    width: "8%",
    render: (updated_at: any) => renderDate(updated_at),
    sorter: (a: { updated_at: any }, b: { updated_at: any }) =>
      date_sorter(a.updated_at, b.updated_at),
  },
];

export const expandedRowRender = (data?: collection_result_analysis) => {
  let runningProcesses = data?.processes?.filter((i) => i.is_running);

  const procesColumns: TableColumnsType<any> = [
    {
      title: "Process",
      dataIndex: "process",
      key: "process",
    },
    {
      title: "Status (is running)",
      dataIndex: "is_running",
      key: "is_running",
      width: "15%",
      render: (is_running: boolean) => {
        return is_running ? (
          <CheckOutlined />
        ) : (
          <> </>
        );
      },
    },
    {
      title: "Runs in Docker",
      dataIndex: "is_docker",
      key: "is_docker",
      width: "15%",
      render: (is_docker: boolean) => {
        return is_docker ? (
          <CheckOutlined />
        ) : (
          <> </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
    },
  ];

  const filesColumns: TableColumnsType<any> = [
    {
      title: "File name",
      dataIndex: "file_name",
      key: "file_name",
      width: "25%",
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      ellipsis: true,
    },
  ];

  const keysColumns: TableColumnsType<any> = [
    {
      title: "File name",
      dataIndex: "file_name",
      key: "file_name",
      width: "25%",
    },
    {
      title: "algorithm",
      dataIndex: "algorithm",
      key: "algorithm",
      ellipsis: true,
    },
    {
      title: "Is private",
      dataIndex: "is_private",
      key: "is_private",
      render: (is_private: boolean) => {
        return is_private ? (
          <CheckOutlined />
        ) : (
          <> </>
        );
      },
    },
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
      ellipsis: true,
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
    },
  ];

  return (
    <>
      {runningProcesses && runningProcesses.length > 0 ? (
        <>
          Processes
          <Table
            columns={procesColumns}
            rowKey="id"
            className="expandTable"
            size="small"
            dataSource={runningProcesses}
            pagination={false}
          />
        </>
      ) : (
        <></>
      )}
      {data?.miscellaneous_files && data?.miscellaneous_files.length > 0 ? (
        <>
          SSH Miscellaneous files
          <Table
            columns={filesColumns}
            rowKey="content"
            className="expandTable"
            size="small"
            dataSource={data?.miscellaneous_files}
            pagination={false}
          />
        </>
      ) : (
        <></>
      )}
      {data?.keys && data?.keys.length > 0 ? (
        <>
          SSH keys
          <Table
            columns={keysColumns}
            rowKey="key"
            className="expandTable"
            size="small"
            dataSource={data?.keys}
            pagination={false}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};
