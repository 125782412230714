import React from 'react';
import { ShollectorResult } from '@/shared';
import { Table } from 'antd';
import {renderDate, date_sorter} from "@/shared";
import TextArea from "antd/es/input/TextArea";


type Props = {
  shollector_results: ShollectorResult[]
}

export const ShollectorResults: React.FC<Props> = ({ shollector_results }) => {
  const shollectorResultsColumns = [
    {
      title: 'Parsed result',
      dataIndex: 'content',
      key: 'content',
      width: "80%",
      render: (parsed: string) => {
        let content = ""
        if (parsed.trim().length === 0) {
          content = "Empty result"
        } else {
          try {
            const jsonObject = JSON.parse(parsed);
            content = JSON.stringify(jsonObject, null, 4)
          }
          catch (error) {
            content = "Error parsing result json: " + error;
          }
        }
        return (
          <TextArea className='log_text' readOnly autoSize={{ maxRows: 15 }}
              value={content}
          />
        );
      },    
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text: any) => renderDate(text),
      sorter: (a: { created_at: any }, b: { created_at: any }) =>
        date_sorter(a.created_at, b.created_at),
    },
    {
      title: 'Updated at',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (text: any) => renderDate(text),
      sorter: (a: { updated_at: any }, b: { updated_at: any }) =>
        date_sorter(a.updated_at, b.updated_at),
    },
  ];
  
  return (
    <div>
      <Table
        className='credsTable'
        columns={shollectorResultsColumns}
        dataSource={shollector_results}
        pagination={false}
        rowKey='id' />
    </div>
  )
}
