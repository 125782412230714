import { useHostState } from "../store";
import { onlyUnique } from "@/shared";
import { useParams } from "react-router-dom";

export const InfoSection = () => {
  const [state] = useHostState();

  const routeParams = useParams();

  const render_ips = (items: any) => {
    let ips = items ? items.split(",") : [];
    const array_size = (ips.length + 1) / 3;
    const sliced_array = [];
    for (let i = 0; i < ips?.length; i += array_size) {
      sliced_array.push(ips?.slice(i, i + array_size));
    }
    var arr: JSX.Element[] = [];
    sliced_array.forEach((element, index) => {
      arr.push(
        <div key={index} style={{ padding: "0 20px 0 20px" }}>
          {element.map((item: any, index2: any) => {
            return (
              <span key={(index + 1) * (index2 + 1)}>
                {item}
                <br />
              </span>
            );
          })}
        </div>
      );
    });
    return arr;
  };

  return (
    <div className="host_info_header">
      <div className="left_block">
        <b>EXTERNAL IP:</b>{" "}
        <>
          &nbsp; &emsp; &nbsp;
          {routeParams.id === state.hostDetails?.target_host?.id &&
            state.hostDetails.target_host?.external_ip}
        </>
        <br />
        <b>OS:</b>{" "}
        <>
          &emsp; &nbsp; &emsp; &emsp;&emsp;&emsp;&emsp;
          {routeParams.id === state.hostDetails?.target_host?.id &&
            state.hostDetails.target_host?.os}
        </>
        <br />
        <b>USERNAME:</b>
        <>
          &emsp; &emsp; &nbsp;{" "}
          {routeParams.id === state.hostDetails?.target_host?.id &&
            state.hostDetails.target_host?.username}
        </>
        <br />
        <b>MACHINE ID:</b>
        <>
          &emsp; &emsp;{" "}
          {routeParams.id === state.hostDetails?.target_host?.id &&
            state.hostDetails.target_host?.machine_id}
        </>
        <br />
      </div>
      <div className="right_block">
        <div className="ips_header">
          {" "}
          <b>INTERNAL IPs: </b>
        </div>{" "}
        <div className="ips_value">
          {render_ips(
            routeParams.id === state.hostDetails?.target_host?.id &&
              state.hostDetails.target_host?.internal_ips
                .split(",")
                .filter(onlyUnique)
                .join(",")
          )}
        </div>
        <br />
      </div>
    </div>
  );
};
