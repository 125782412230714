import { getOptions, sortOptions, SearchTargetsModel } from "@/shared";

const default_header_targets = ['all', 'none'];
const default_target = ['none'];
const default_search_types = ['all']

export const getTargetSelects = (search: SearchTargetsModel[] | undefined) => {
    let targets = search ? search?.map(item => item.name) : [];
    return getOptions(default_target).concat(sortOptions(getOptions(targets)))
}

export const getHeaderTargetSelects = (search: SearchTargetsModel[] | undefined) => {
    let headers = search ? search?.map(item => item.name) : [];
    return getOptions(default_header_targets).concat(sortOptions(getOptions(headers)))
}

export const get_search_types = (types_list: string[]) => getOptions(default_search_types.concat(types_list))


