import {
  ColumnsType,
  FilterValue,
  SorterResult,
} from "antd/es/table/interface";
import {
  Host,
  HostSelect,
  actionsType,
  getCache,
  setCache,
} from "@/shared";
import { ITargetHostState } from "./ITargetHostState";
import AuthLocalStorage from "../../../AuthLocalStorage";
import { NetcatApi, TargetApi } from "@/Api";

const TARGET_CLIENT = new TargetApi();
const CLIENT = new NetcatApi();

export const actions = {
  setActive:
    (isActive: boolean) =>
    ({ setState, getState }: actionsType<ITargetHostState>) =>
      setState({ ...getState(), isActive }),

  setLoading:
    (isLoading: boolean) =>
    ({ setState, getState }: actionsType<ITargetHostState>) =>
      setState({ ...getState(), isLoading }),

  setMulti:
    (isMulti: boolean) =>
    ({ setState, getState }: actionsType<ITargetHostState>) =>
      setState({ ...getState(), isMulti }),

  setAlertId:
    (alert_id: string | undefined) =>
    ({ setState, getState }: actionsType<ITargetHostState>) =>
      setState({ ...getState(), alert_id }),

  setTableData:
    () =>
    async ({ setState, getState }: actionsType<ITargetHostState>) => {
      setState({ ...getState(), isLoading: true });
      let host = AuthLocalStorage.getHostSelects()
        ? JSON.parse(AuthLocalStorage.getHostSelects()!)
        : new HostSelect();
      let tabledata = await TARGET_CLIENT.getData(host);
      setState({ ...getState(), tabledata, isLoading: false });
    },

  deleteTableRecord:
    (recordId: string) =>
    ({ setState, getState }: actionsType<ITargetHostState>) => {
      const tabledata = [
        ...getState().tabledata.filter((record) => record.id !== recordId),
      ];
      setState({ ...getState(), tabledata });
    },

  setTypesList:
    () =>
    async ({ setState, getState }: actionsType<ITargetHostState>) => {
      let types = await getCache("sessionTypes");
      let typesList: string[] = [];
      typesList = types?.length === 0 ? await CLIENT.getSessionsTypes() : types;
      types?.length === 0 && setCache(typesList, "sessionTypes");
      setState({ ...getState(), typesList });
    },

  setSelectedColumns:
    (selectedColumns: ColumnsType<Host>) =>
    ({ setState, getState }: actionsType<ITargetHostState>) =>
      setState({ ...getState(), selectedColumns }),

  changeSelectedColumnsSorting:
    (sorter: SorterResult<Host>) =>
    ({ setState, getState }: actionsType<ITargetHostState>) => {
      const selectedColumns = [
        ...getState().selectedColumns.map((col) => {
          if (col.key === sorter.columnKey) {
            col.sortOrder = sorter.order;
          } else {
            col.sortOrder = undefined;
          }
          return col;
        }),
      ];
      setState({ ...getState(), selectedColumns });
    },

  setSelectedRowKeys:
    (selectedRowKeys: never[]) =>
    ({ setState, getState }: actionsType<ITargetHostState>) =>
      setState({ ...getState(), selectedRowKeys }),

  setFilters:
    (filters: Record<string, FilterValue | null>) =>
    ({ setState, getState }: actionsType<ITargetHostState>) =>
      setState({ ...getState(), filters }),
};
